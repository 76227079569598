@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-6f0c7cd9] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-6f0c7cd9] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-6f0c7cd9] {
  width: 100vw;
  height: 100vh;
}
div[data-v-6f0c7cd9] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-6f0c7cd9],
p[data-v-6f0c7cd9],
span[data-v-6f0c7cd9],
h1[data-v-6f0c7cd9],
h2[data-v-6f0c7cd9],
h3[data-v-6f0c7cd9],
h4[data-v-6f0c7cd9],
h5[data-v-6f0c7cd9],
h6[data-v-6f0c7cd9],
button[data-v-6f0c7cd9],
input[data-v-6f0c7cd9],
textarea[data-v-6f0c7cd9],
select[data-v-6f0c7cd9] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-6f0c7cd9] {
  color: #d9d9d9;
}
a[data-v-6f0c7cd9] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-6f0c7cd9] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-6f0c7cd9]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-6f0c7cd9]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-6f0c7cd9]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-6f0c7cd9] {
  touch-action: pan-y;
}
.modal[data-v-6f0c7cd9] {
  top: 100px;
  min-width: 200px;
  height: auto;
  margin: 0 10px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: "title close" "description description" "actions actions";
  background-color: #45474A;
  overflow: hidden;
  position: absolute;
}
.modal .title[data-v-6f0c7cd9] {
  padding: 10px;
  font-weight: bold;
  color: #f5f5f5;
  grid-area: title;
}
.modal .close[data-v-6f0c7cd9] {
  padding-right: 10px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  grid-area: close;
}
.modal .description[data-v-6f0c7cd9] {
  padding: 10px;
  color: #ffffff;
  background-color: #595A5A;
  grid-area: description;
  white-space: pre-line;
}
.modal .actions[data-v-6f0c7cd9] {
  padding: 10px;
  grid-area: actions;
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
}
.modal .actions button[data-v-6f0c7cd9] {
  padding: 5px 10px;
  margin-left: 10px;
  border: none;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  transition: 0.25s;
  background-color: #8c8c8c;
}
.modal .actions button[data-v-6f0c7cd9]:first-child {
  background-color: #EB5757;
}
.modal .actions button[data-v-6f0c7cd9]:first-child:hover {
  background-color: #F78783;
}
.modal .actions button[data-v-6f0c7cd9]:nth-child(2) {
  background-color: #7DC579;
}
.modal .actions button[data-v-6f0c7cd9]:nth-child(2):hover {
  background-color: #9ACD96;
}
