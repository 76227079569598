@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-21775718] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-21775718] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-21775718] {
  width: 100vw;
  height: 100vh;
}
div[data-v-21775718] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-21775718],
p[data-v-21775718],
span[data-v-21775718],
h1[data-v-21775718],
h2[data-v-21775718],
h3[data-v-21775718],
h4[data-v-21775718],
h5[data-v-21775718],
h6[data-v-21775718],
button[data-v-21775718],
input[data-v-21775718],
textarea[data-v-21775718],
select[data-v-21775718] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-21775718] {
  color: #d9d9d9;
}
a[data-v-21775718] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-21775718] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-21775718]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-21775718]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-21775718]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-21775718] {
  touch-action: pan-y;
}
.check[data-v-21775718] {
  padding: 15px !important;
  background: #1F2124;
  margin-bottom: 10px;
  border-radius: 10px;
  -webkit-user-select: none;
          user-select: none;
  overscroll-behavior-y: auto;
  position: relative;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.check .title[data-v-21775718] {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.check input[data-v-21775718],
.check textarea[data-v-21775718] {
  width: 100%;
  background: #313438;
  border-radius: 5px;
  margin: 0;
  padding: 10px;
  border: none;
  outline: none;
  resize: none;
  margin-bottom: 5px;
}
.check textarea[data-v-21775718]::-webkit-input-placeholder {
  font-style: italic;
}
.check textarea[data-v-21775718]::placeholder {
  font-style: italic;
}
.check .check-delete[data-v-21775718] {
  top: 10px;
  right: 10px;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
}
.check .contentWrapper[data-v-21775718] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
}
.check .contentWrapper .info[data-v-21775718] {
  word-wrap: break-word;
  overflow: hidden;
}
.check .contentWrapper .info p[data-v-21775718] {
  margin: 0;
}
.check .contentWrapper .info .name[data-v-21775718] {
  font-weight: bold;
}
.check .contentWrapper .info .description[data-v-21775718] {
  margin-top: 10px;
}
.check .contentWrapper .control[data-v-21775718] {
  width: 45px;
  height: 45px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #434343;
  font-size: 16px;
  font-weight: bold;
  color: #d9d9d9;
  transition: 0.25s;
}
.check .contentWrapper .control.setStatus[data-v-21775718] {
  cursor: pointer;
}
.check .contentWrapper .control.setStatus .binary-marker[data-v-21775718] {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  opacity: 0.35;
  background: radial-gradient(#bfbfbf, rgba(191, 191, 191, 0.4));
}
.check .contentWrapper .control.setStatus .marker[data-v-21775718] {
  width: 27px !important;
  height: 16px;
  pointer-events: none;
  border-radius: 0 !important;
  background: none !important;
  display: grid;
  grid-template-columns: 3px 3px 3px 3px 3px;
  grid-gap: 3px;
}
.check .contentWrapper .control.setStatus .marker div[data-v-21775718] {
  border-radius: 3px;
  height: 16px;
  background-color: #bfbfbf;
  opacity: 0.4;
}
.check .contentWrapper .control.setStatus .marker div[data-v-21775718]:nth-child(2),
.check .contentWrapper .control.setStatus .marker div[data-v-21775718]:nth-child(4) {
  opacity: 0.75;
}
.check .contentWrapper .control.setStatus .marker div[data-v-21775718]:nth-child(3) {
  opacity: 1;
}
.check .contentWrapper .control.setStatus.disabled[data-v-21775718] {
  cursor: not-allowed;
}
.check .contentWrapper .control.setStatus.ok[data-v-21775718] {
  background-color: #7DC579;
}
.check .contentWrapper .control.setStatus.notOk[data-v-21775718] {
  background-color: #EB5757;
}
.check .contentWrapper .rating-binary-0[data-v-21775718] {
  background-color: #EB5757;
}
.check .contentWrapper .rating-binary-1[data-v-21775718] {
  background-color: #7DC579;
}
.check .contentWrapper .rating-int-0[data-v-21775718] {
  background-color: #EB5757;
}
.check .contentWrapper .rating-int-1[data-v-21775718] {
  background-color: #EB5757;
}
.check .contentWrapper .rating-int-2[data-v-21775718] {
  background-color: #EB5757;
}
.check .contentWrapper .rating-int-3[data-v-21775718] {
  background-color: #F2994A;
}
.check .contentWrapper .rating-int-4[data-v-21775718] {
  background-color: #E6BD45;
}
.check .contentWrapper .rating-int-5[data-v-21775718] {
  background-color: #7DC579;
}
.check .contentWrapper .rating-int-true[data-v-21775718] {
  background-color: #7DC579;
}
.check .contentWrapper .rating-int-false[data-v-21775718] {
  background-color: #EB5757;
}
.check .contentWrapper .control.add-ticket[data-v-21775718] {
  background-color: #7DC579;
}
.check .contentWrapper .control.add-report[data-v-21775718] {
  background-color: #434343;
}
.check .contentWrapper .control.add-report.disabled[data-v-21775718] {
  background-color: #595959;
  cursor: not-allowed;
}
.check .contentWrapper .control.add-report.disabled i[data-v-21775718] {
  color: rgba(255, 255, 255, 0.17);
}
.check .reportWrapper[data-v-21775718] {
  outline: none;
  margin-top: 20px;
}
.check .reportWrapper .attached-images-container .title[data-v-21775718] {
  margin: 0;
  display: none;
  font-size: 16px;
}
.check .reportWrapper .attached-images-container .title.active[data-v-21775718] {
  display: block;
}
.check .reportWrapper .attached-images-container .attached-images[data-v-21775718] {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.check .reportWrapper .reportControls[data-v-21775718] {
  width: 100%;
  min-height: 45px;
  margin-top: 5px;
  text-align: right;
  display: flex;
  grid-gap: 10px;
}
.check .reportWrapper .reportControls .reverseContainer[data-v-21775718] {
  display: flex;
}
.check .reportWrapper .reportControls .reverseContainer .attach[data-v-21775718] {
  font-size: 25px;
  padding: 10px 18px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.check .reportWrapper .reportControls .reverseContainer .attach input[data-v-21775718] {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: blue;
  position: absolute;
}
.check .reportWrapper .reportControls .reverseContainer .submit[data-v-21775718] {
  width: 45px;
  height: 45px;
  display: inline-flex;
  text-align: center;
  border-radius: 50%;
  color: #d9d9d9;
  font-weight: bold;
  font-size: 30px;
  transition: 0.25s;
  background-color: #595959;
  position: absolute;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.check .reportWrapper .reportControls .reverseContainer .submit .content[data-v-21775718] {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.check .reportWrapper .reportControls .reverseContainer .submit .content i.fa-check[data-v-21775718] {
  font-size: 20px;
}
.check .reportWrapper .reportControls .reverseContainer .submit .content i.fa-telegram-plane[data-v-21775718] {
  font-size: 28px;
}
.check .reportWrapper .reportControls .reverseContainer .submit .content .progress[data-v-21775718] {
  font-size: 14px;
}
.check .reportWrapper .reportControls .reverseContainer .submit.validated[data-v-21775718] {
  background-color: #7DC579;
}
.check .reportWrapper .reportControls .reverseContainer .submit.loading[data-v-21775718] {
  background-color: skyblue;
}
.check .reportWrapper .reportControls .reverseContainer .submit.loading .water[data-v-21775718] {
  width: 45px;
  height: 45px;
  position: absolute;
  -webkit-transform: rotate(-55deg);
          transform: rotate(-55deg);
  overflow: hidden;
  transition: 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check .reportWrapper .reportControls .reverseContainer .submit.loading .water .r1[data-v-21775718],
.check .reportWrapper .reportControls .reverseContainer .submit.loading .water .r2[data-v-21775718] {
  position: absolute;
  width: 45px;
  height: 45px;
}
.check .reportWrapper .reportControls .reverseContainer .submit.loading .water .r1[data-v-21775718] {
  border-radius: 45%;
  background: rgba(255, 255, 255, 0.7);
  -webkit-animation: wave-data-v-21775718 5s linear infinite;
          animation: wave-data-v-21775718 5s linear infinite;
}
.check .reportWrapper .reportControls .reverseContainer .submit.loading .water .r2[data-v-21775718] {
  border-radius: 35%;
  background: red;
  opacity: 0.3;
  -webkit-animation: wave-data-v-21775718 5s linear infinite;
          animation: wave-data-v-21775718 5s linear infinite;
}
.check .reportWrapper .reportControls .reverseContainer .submit.loaded[data-v-21775718] {
  background-color: #7DC579;
}
.check .reportWrapper .reportControls .reverseContainer .submit.loaded .water .r1[data-v-21775718],
.check .reportWrapper .reportControls .reverseContainer .submit.loaded .water .r2[data-v-21775718] {
  display: none;
}
@-webkit-keyframes wave-data-v-21775718 {
0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@keyframes wave-data-v-21775718 {
0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
.check .reportWrapper .reportControls .reverseContainer.add-ticket-container[data-v-21775718] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}
.check .reportWrapper .reportControls .reverseContainer.add-ticket-container .add-ticket[data-v-21775718] {
  padding: 8px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  pointer-events: all;
  background-color: #26629f;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.25s;
}
.check .reportWrapper .reportControls .reverseContainer.add-ticket-container .add-ticket .plus-icon[data-v-21775718] {
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: 0.25s;
  position: relative;
}
.check .reportWrapper .reportControls .reverseContainer.add-ticket-container .add-ticket .plus-icon li[data-v-21775718] {
  top: 6px;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  position: absolute;
  overflow: hidden;
  background-color: #ffffff;
}
.check .reportWrapper .reportControls .reverseContainer.add-ticket-container .add-ticket .plus-icon li[data-v-21775718]:last-child {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.check .reportWrapper .reportControls .reverseContainer.add-ticket-container .add-ticket .plus-icon.active[data-v-21775718] {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.check .reportWrapper .reportControls .reverseContainer.add-ticket-container .add-ticket span[data-v-21775718] {
  margin-left: 5px;
  color: #ffffff;
  font-weight: bold;
}
.check .reportWrapper .reportControls .reverseContainer.add-ticket-container .add-ticket[data-v-21775718]:hover {
  background-color: #336fac;
}
.check .reportWrapper .add-ticket-form[data-v-21775718] {
  margin-top: 15px;
}
.check .reportWrapper .add-ticket-form .ticket-info-section[data-v-21775718] {
  margin-top: 15px;
}
.check .reportWrapper .add-ticket-form .ticket-info-section .priority-selector-input[data-v-21775718],
.check .reportWrapper .add-ticket-form .ticket-info-section input[data-v-21775718],
.check .reportWrapper .add-ticket-form .ticket-info-section select[data-v-21775718],
.check .reportWrapper .add-ticket-form .ticket-info-section textarea[data-v-21775718] {
  width: 100%;
  display: block;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  padding: 5px;
  background: #313438;
}
.check .reportWrapper .add-ticket-form .ticket-info-section textarea[data-v-21775718] {
  height: 57px;
  resize: none;
}
.check .reportWrapper .add-ticket-form .ticket-info-section input[data-v-21775718]:focus,
.check .reportWrapper .add-ticket-form .ticket-info-section select[data-v-21775718]:focus,
.check .reportWrapper .add-ticket-form .ticket-info-section textarea[data-v-21775718]:focus {
  outline: none;
}
.check .reportWrapper .add-ticket-form .ticket-info-section input[data-v-21775718],
.check .reportWrapper .add-ticket-form .ticket-info-section select[data-v-21775718] {
  height: 32px;
}
.check .reportWrapper .add-ticket-form .ticket-info-section .new-ticket-select[data-v-21775718] {
  width: 100%;
}
.check .reportWrapper .add-ticket-form .ticket-info-section p[data-v-21775718]:first-child {
  margin-top: 0;
}
.check .reportWrapper .add-ticket-form .ticket-info-section p[data-v-21775718] {
  margin-bottom: 0;
}
.check .reportWrapper .add-ticket-form .ticket-info-section .attached-images[data-v-21775718] {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.check .reportWrapper .add-ticket-form .ticket-info-section[data-v-21775718]:last-child {
  margin-bottom: 20px;
}
.check.isIncorrect[data-v-21775718] {
  padding-left: 7px;
  border-left: 8px solid #BF9730;
}
.check.selected[data-v-21775718] {
  padding-left: 7px;
  border-left: 8px solid #40a9ff;
}
