@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-47e2b18d] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-47e2b18d] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-47e2b18d] {
  width: 100vw;
  height: 100vh;
}
div[data-v-47e2b18d] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-47e2b18d],
p[data-v-47e2b18d],
span[data-v-47e2b18d],
h1[data-v-47e2b18d],
h2[data-v-47e2b18d],
h3[data-v-47e2b18d],
h4[data-v-47e2b18d],
h5[data-v-47e2b18d],
h6[data-v-47e2b18d],
button[data-v-47e2b18d],
input[data-v-47e2b18d],
textarea[data-v-47e2b18d],
select[data-v-47e2b18d] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-47e2b18d] {
  color: #d9d9d9;
}
a[data-v-47e2b18d] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-47e2b18d] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-47e2b18d]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-47e2b18d]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-47e2b18d]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-47e2b18d] {
  touch-action: pan-y;
}
section.selector[data-v-47e2b18d] {
  max-height: 100%;
  margin-top: 10px;
  overflow-y: scroll;
  padding: 15px;
  padding-top: 8px;
  grid-gap: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #1F2124;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
section.selector .apply-selector-btn-wrapper[data-v-47e2b18d] {
  display: inline-flex !important;
  align-self: flex-end;
  border-radius: 5px !important;
  overflow: hidden;
}
section.selector .apply-selector-btn-wrapper .apply-selector-btn[data-v-47e2b18d] {
  height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  padding: 10px;
  align-self: flex-end;
  cursor: pointer;
  transition: 0.25s;
  background: #313438;
}
section.selector .apply-selector-btn-wrapper .apply-selector-btn[data-v-47e2b18d]:hover {
  background: #45474A;
}
section.selector .title[data-v-47e2b18d] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.selector .title .label[data-v-47e2b18d] {
  display: flex;
  align-items: flex-start;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
}
section.selector .title .label span[data-v-47e2b18d],
section.selector .title .label i[data-v-47e2b18d] {
  cursor: pointer;
  color: #45474A;
  font-weight: bold;
}
section.selector .title i[data-v-47e2b18d] {
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #45474A;
  transition: 0.15s;
}
section.selector .title i[data-v-47e2b18d]:hover {
  color: #99A0A3;
}
section.selector .ant-tag[data-v-47e2b18d] {
  border: none;
  outline: none;
  padding: 6px;
  background-color: #313438;
  font-weight: bold;
}
section.selector .date-picker[data-v-47e2b18d] {
  background-color: #313438 !important;
  max-width: 100%;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  padding: 12px;
  box-sizing: border-box;
  background-color: rgba(31, 33, 36, 0.8);
  border: none;
}
section.selector .date-picker .anticon-swap-right svg[data-v-47e2b18d] {
  fill: red;
}
section.selector .tesk-selector[data-v-47e2b18d] {
  overflow: hidden;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  background-color: rgba(31, 33, 36, 0.8);
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
section.selector .tesk-selector .progress[data-v-47e2b18d] {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
}
section.selector .tesk-selector .progress span[data-v-47e2b18d]:first-child {
  font-size: 18px;
  font-weight: bold;
}
section.selector .tesk-selector .progress span[data-v-47e2b18d]:last-child {
  font-size: 10px;
  color: #595959;
}
section.selector .tesk-selector .description[data-v-47e2b18d] {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.selector .tesk-selector .description span[data-v-47e2b18d] {
  font-size: 16px;
  font-weight: bold;
}
section.selector .tesk-selector.active[data-v-47e2b18d]::after {
  width: 35px;
  height: 35px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: -18px;
  bottom: -18px;
  background: #7DC579;
  position: absolute;
  content: " ";
  transition: 0.25s;
}
