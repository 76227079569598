.device-card[data-v-b3392d8c] {
  min-width: 300px;
  border-radius: 8px;
  padding: 10px;
  background-color: #1F2124;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.device-card .name[data-v-b3392d8c] {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.25s;
}
.device-card .name i[data-v-b3392d8c] {
  margin-left: 5px;
  font-size: 14px;
}
.device-card .name[data-v-b3392d8c]:hover {
  color: #40a9ff;
}
.device-card .status[data-v-b3392d8c] {
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  position: absolute;
}
.device-card .status.online[data-v-b3392d8c] {
  color: #9ACD96;
}
.device-card .status.offline[data-v-b3392d8c] {
  color: #F78783;
}
.device-card .group-name[data-v-b3392d8c],
.device-card .group-role[data-v-b3392d8c] {
  margin-top: 5px;
  margin-right: 5px;
  padding: 4px 6px;
  display: inline-block;
  font-weight: bold;
  border-radius: 5px;
  background-color: #313438;
}
.device-card .log-statistics[data-v-b3392d8c] {
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  -webkit-user-select: none;
          user-select: none;
  font-size: 12px;
  grid-gap: 10px;
}
.device-card .log-statistics .errors[data-v-b3392d8c],
.device-card .log-statistics .logs[data-v-b3392d8c],
.device-card .log-statistics .warnings[data-v-b3392d8c] {
  display: grid;
  grid-template-areas: "counter label" "heat-map heat-map";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  border-radius: 5px;
  cursor: pointer;
}
.device-card .log-statistics .errors .label[data-v-b3392d8c],
.device-card .log-statistics .logs .label[data-v-b3392d8c],
.device-card .log-statistics .warnings .label[data-v-b3392d8c] {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 2px;
  grid-area: label;
}
.device-card .log-statistics .errors .counter[data-v-b3392d8c],
.device-card .log-statistics .logs .counter[data-v-b3392d8c],
.device-card .log-statistics .warnings .counter[data-v-b3392d8c] {
  grid-area: counter;
}
.device-card .log-statistics .errors .counter .value[data-v-b3392d8c],
.device-card .log-statistics .logs .counter .value[data-v-b3392d8c],
.device-card .log-statistics .warnings .counter .value[data-v-b3392d8c] {
  font-size: 22px;
}
.device-card .log-statistics .errors .counter .period[data-v-b3392d8c],
.device-card .log-statistics .logs .counter .period[data-v-b3392d8c],
.device-card .log-statistics .warnings .counter .period[data-v-b3392d8c] {
  margin-left: 5px;
  font-size: 16px;
  color: #A5ACB0;
}
.device-card .log-statistics .errors .heat-map[data-v-b3392d8c],
.device-card .log-statistics .logs .heat-map[data-v-b3392d8c],
.device-card .log-statistics .warnings .heat-map[data-v-b3392d8c] {
  width: 100%;
  display: flex;
  grid-area: heat-map;
  grid-gap: 4px;
}
.device-card .log-statistics .errors .heat-map .dot-value[data-v-b3392d8c],
.device-card .log-statistics .logs .heat-map .dot-value[data-v-b3392d8c],
.device-card .log-statistics .warnings .heat-map .dot-value[data-v-b3392d8c] {
  width: 100%;
  height: 24px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  background-color: #EB5757;
}
.device-card .log-statistics .errors .heat-map .dot-value.ok[data-v-b3392d8c],
.device-card .log-statistics .logs .heat-map .dot-value.ok[data-v-b3392d8c],
.device-card .log-statistics .warnings .heat-map .dot-value.ok[data-v-b3392d8c] {
  background-color: #1F2124;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.device-card .log-statistics .errors .heat-map .dot-value.ok span[data-v-b3392d8c],
.device-card .log-statistics .logs .heat-map .dot-value.ok span[data-v-b3392d8c],
.device-card .log-statistics .warnings .heat-map .dot-value.ok span[data-v-b3392d8c] {
  color: #75797D;
}
.device-card .log-statistics .logs .heat-map .dot-value[data-v-b3392d8c] {
  background-color: #595959;
}
.device-card .log-statistics .warnings .counter[data-v-b3392d8c] {
  color: #F2D66F;
}
.device-card .log-statistics .warnings .heat-map .dot-value[data-v-b3392d8c] {
  background-color: #BF9730;
}
.device-card .log-statistics .errors .counter[data-v-b3392d8c] {
  color: #F78783;
}
.device-card .log-statistics .errors .heat-map .dot-value[data-v-b3392d8c] {
  background-color: #EB5757;
}
.device-card .services-wrapper[data-v-b3392d8c] {
  position: relative;
}
.device-card .services-wrapper .status-indicator[data-v-b3392d8c] {
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.device-card .services-wrapper .status-indicator span[data-v-b3392d8c] {
  text-align: center;
  align-items: center;
  font-size: 18px;
  color: #75797D;
}
.device-card .services-wrapper .services[data-v-b3392d8c] {
  display: flex;
  padding: 5px;
  height: 51px;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  grid-gap: 5px;
  position: relative;
  z-index: 1;
}
.device-card .services-wrapper .services .service-btn[data-v-b3392d8c] {
  padding: 10px 14px;
  border-radius: 4px;
  background-color: #313438;
  cursor: pointer;
  transition: 0.25s;
}
.device-card .services-wrapper .services .service-btn span[data-v-b3392d8c] {
  font-weight: bold;
}
.device-card .services-wrapper .services .service-btn[data-v-b3392d8c]:hover {
  background-color: #595A5A;
}
.device-card .services-wrapper .services .service-btn.reload[data-v-b3392d8c]:hover {
  background-color: #BF9730;
}
.device-card .services-wrapper .services .service-btn.reboot[data-v-b3392d8c]:hover {
  background-color: #EB5757;
}
.device-card .services-wrapper .services .service-btn.monitor-dpms-on[data-v-b3392d8c]:hover {
  background-color: #56b450;
}
.device-card .services-wrapper .services .service-btn.monitor-dpms-off[data-v-b3392d8c]:hover {
  background-color: #BF9730;
}
