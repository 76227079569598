@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0 {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root {
  width: 100vw;
  height: 100vh;
}
div {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
textarea,
select {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i {
  color: #d9d9d9;
}
a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
* {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene {
  touch-action: pan-y;
  margin: 0 auto;
  padding: 15px 15px 15px 15px;
  height: calc(100vh - 45px);
  overflow-y: auto;
}
.scene .grid {
  margin: 0 auto;
}
.scene.mx-lim {
  max-width: 1000px;
}
.pos-statistics.scene .tb {
  border-radius: 4px;
  overflow: hidden;
  stroke: none !important;
}
.pos-statistics.scene .tb rect:first-child {
  fill: #595A5A !important;
  stroke: none !important;
}
.pos-statistics.scene .tb rect:nth-child(2) {
  fill: #000 !important;
  fill-opacity: 0.4 !important;
  opacity: 1 !important;
}
.pos-statistics.scene .tb g:nth-child(3) rect,
.pos-statistics.scene .tb g:nth-child(4) rect {
  fill: #1890ff !important;
}
.pos-statistics.scene .tb g:nth-child(5) text {
  fill: #1890ff !important;
}
