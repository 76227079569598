@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-82d5525a] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-82d5525a] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-82d5525a] {
  width: 100vw;
  height: 100vh;
}
div[data-v-82d5525a] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-82d5525a],
p[data-v-82d5525a],
span[data-v-82d5525a],
h1[data-v-82d5525a],
h2[data-v-82d5525a],
h3[data-v-82d5525a],
h4[data-v-82d5525a],
h5[data-v-82d5525a],
h6[data-v-82d5525a],
button[data-v-82d5525a],
input[data-v-82d5525a],
textarea[data-v-82d5525a],
select[data-v-82d5525a] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-82d5525a] {
  color: #d9d9d9;
}
a[data-v-82d5525a] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-82d5525a] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-82d5525a]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-82d5525a]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-82d5525a]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-82d5525a] {
  touch-action: pan-y;
}
.scene.profile[data-v-82d5525a] {
  padding: 10px;
}
.scene.profile .profile-card[data-v-82d5525a] {
  widows: 100%;
  min-height: 140px;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 140px 1fr 8px;
  background-color: #141414;
@include boxShadow();
}
.scene.profile .profile-card .online-marker[data-v-82d5525a] {
  width: 8px;
  height: 100%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #56b450;
}
.scene.profile .profile-card .profile-photo[data-v-82d5525a] {
  width: 140px;
  height: 140px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #1f1f1f;
  background-position: 50%;
  background-size: cover;
}
.scene.profile .profile-card .profile-info[data-v-82d5525a] {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  position: relative;
}
.scene.profile .profile-card .profile-info .user-name[data-v-82d5525a] {
  height: 20px;
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}
.scene.profile .profile-card .profile-info .profileInput[data-v-82d5525a] {
  padding-top: 15px;
  background: none;
  display: block;
  border: none;
  height: 34px;
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}
.scene.profile .profile-card .profile-info .profileInput[data-v-82d5525a]:focus {
  outline: none;
}
.scene.profile .profile-card .profile-info .phoneInput[data-v-82d5525a],
.scene.profile .profile-card .profile-info .emailInput[data-v-82d5525a] {
  height: 22px;
  padding: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scene.profile .profile-card .profile-info .branchSelect[data-v-82d5525a] {
  background: none;
  outline: none;
  border: none;
}
.scene.profile .profile-card .profile-info .user-branch-position[data-v-82d5525a] {
  margin-top: 8px;
  display: inline-grid;
  grid-template-columns: auto auto;
  font-size: 10px;
}
.scene.profile .profile-card .profile-info .user-branch-position .user-branch[data-v-82d5525a],
.scene.profile .profile-card .profile-info .user-branch-position .user-position[data-v-82d5525a] {
  border-radius: 3px;
  padding: 1px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
}
.scene.profile .profile-card .profile-info .user-branch-position .user-branch[data-v-82d5525a] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #2238A9;
}
.scene.profile .profile-card .profile-info .user-branch-position .user-position[data-v-82d5525a] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #801D82;
}
.scene.profile .profile-card .profile-info .user-contacts[data-v-82d5525a] {
  margin-top: 15px;
  font-size: 14px;
}
.scene.profile .profile-card .profile-info .user-contacts .phone[data-v-82d5525a],
.scene.profile .profile-card .profile-info .user-contacts .email[data-v-82d5525a] {
  display: flex;
  align-items: center;
}
.scene.profile .profile-card .profile-info .user-contacts .fas[data-v-82d5525a] {
  margin-right: 3px;
}
.scene.profile .profile-card .profile-info .edit-lock[data-v-82d5525a] {
  font-size: 20px;
  right: 10px;
  bottom: 10px;
  position: absolute;
}
