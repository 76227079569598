.about-app[data-v-b00f8bef] {
  max-height: 100vh;
  overflow-y: auto;
}
.about-app .wrapper[data-v-b00f8bef] {
  max-width: 500px;
  margin: 0 auto;
  padding: 15px 15px;
  position: relative;
}
.about-app .wrapper .control-section[data-v-b00f8bef] {
  margin-bottom: 30px;
}
.about-app .wrapper .control-section .section-title[data-v-b00f8bef] {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: lighter;
  color: #8c8c8c;
}
.about-app .wrapper .control-section .control-elem[data-v-b00f8bef] {
  height: 30px;
  margin-bottom: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  box-sizing: content-box;
  cursor: pointer;
}
.about-app .wrapper .control-section .control-elem .fa-chevron-right[data-v-b00f8bef] {
  color: #d9d9d9;
}
.about-app .wrapper .control-section .control-elem.disabled[data-v-b00f8bef] {
  cursor: not-allowed;
}
.about-app .wrapper .control-section .control-elem.disabled .fa-chevron-right[data-v-b00f8bef] {
  color: #434343;
}
.about-app .wrapper .control-section .control-elem.disabled .controlName[data-v-b00f8bef] {
  color: #434343;
}
