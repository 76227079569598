.ant-picker {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background: #313438;
}
.ant-picker-panel {
  border-color: #434343;
}
.ant-select .ant-select-auto-complete .branch-selector .ant-select-single .ant-select-show-search {
  width: 100% !important;
  height: 40px;
  border-radius: 5px;
  padding: 12px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #313438;
  border: none;
  color: #ffffff;
}
.ant-tag-has-color {
  font-weight: bold;
  color: #ffffff;
}
.ant-select-dropdown {
  background-color: #1F2124;
  font-weight: bold;
}
.ant-select-item-option-content {
  color: #ffffff;
  font-family: Arial !important;
  font-weight: bold;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background-color: #14cab9 !important;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active .ant-select-item-option-content {
  color: #1F2124 !important;
  font-family: Arial !important;
  font-weight: bold;
}
.ant-tag {
  margin: 3px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ant-picker-datetime-panel,
.ant-picker-panel-container .ant-picker-panels {
  max-width: 100vw;
  width: auto;
  left: 10px;
  right: 10px;
  height: auto;
  justify-content: center;
  flex-wrap: wrap;
}
.ant-picker-time-panel {
  border-left: none !important;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-color: #434343 !important;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #9ACD96;
  border-color: #9ACD96;
  background: #45474A;
}
.ant-picker-range-arrow::after {
  border-color: #1F2124 transparent;
}
.ant-picker-panel-container {
  background: #1F2124;
}
.ant-picker-panel-container .ant-picker-cell-disabled::before {
  background: #1F2124;
}
.ant-picker-panel-container .ant-picker-cell-in-range::before {
  background: #313438;
}
.ant-picker-panel-container .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-panel-container .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-panel-container .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel-container .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-panel-container .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-panel-container .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-panel-container .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
  background: #45474A;
}
.ant-picker-panel-container .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
.ant-picker-panel-container .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  background: #14cab9;
}
.ant-picker-panel-container .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-panel-container .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  border-color: #1f1f1f;
  background: #313438;
}
.ant-picker-panel-container .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-panel-container .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #14cab9;
}
.ant-picker-panel-container .ant-picker-header {
  border-color: #434343;
}
.ant-picker-panel-container .ant-picker-header-view {
  color: #d9d9d9;
}
.ant-picker-panel-container .ant-picker-header-view,
.ant-picker-panel-container .ant-picker-time-panel-cell-inner {
  font-family: Arial !important;
  color: #d9d9d9 !important;
}
.ant-picker-panel-container .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  color: #d9d9d9 !important;
  background-color: #1890ff;
}
.ant-picker-panel-container .ant-picker-time-panel-cell-inner:hover {
  color: #1f1f1f !important;
}
.ant-picker-panel-container .ant-btn.ant-btn-primary.ant-btn-sm:disabled {
  background-color: #262626;
}
.ant-picker-panel-container .ant-picker-content > thead > tr > th {
  color: #434343;
}
.ant-picker-panel-container .ant-picker-cell-inner {
  color: #d9d9d9;
}
.ant-picker-panel-container .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #434343;
}
.ant-picker-input > input {
  color: #d9d9d9;
}
.ant-picker-clear {
  background: #1F2124;
  padding-right: 1px;
  fill: red;
}
.ant-picker-clear svg path {
  fill: #81868A;
}
.ant-picker-separator svg path {
  fill: #81868A;
}
.ant-picker-suffix svg path {
  fill: #81868A;
}
