@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-3e7764e6] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-3e7764e6] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-3e7764e6] {
  width: 100vw;
  height: 100vh;
}
div[data-v-3e7764e6] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-3e7764e6],
p[data-v-3e7764e6],
span[data-v-3e7764e6],
h1[data-v-3e7764e6],
h2[data-v-3e7764e6],
h3[data-v-3e7764e6],
h4[data-v-3e7764e6],
h5[data-v-3e7764e6],
h6[data-v-3e7764e6],
button[data-v-3e7764e6],
input[data-v-3e7764e6],
textarea[data-v-3e7764e6],
select[data-v-3e7764e6] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-3e7764e6] {
  color: #d9d9d9;
}
a[data-v-3e7764e6] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-3e7764e6] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-3e7764e6]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-3e7764e6]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-3e7764e6]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-3e7764e6] {
  touch-action: pan-y;
  margin: 0 auto;
  padding: 15px 15px 15px 15px;
  height: calc(100vh - 45px);
  overflow-y: auto;
}
.scene .grid[data-v-3e7764e6] {
  margin: 0 auto;
}
.scene.mx-lim[data-v-3e7764e6] {
  max-width: 1000px;
}
.worker-card[data-v-3e7764e6] {
  min-width: 250px;
  height: 275px;
  padding-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  text-align: center;
  background-color: #1F2124;
  touch-action: pan-y;
  position: relative;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.worker-card .color-box[data-v-3e7764e6] {
  width: 100%;
  height: 80px;
  position: absolute;
  background-color: #595959;
  z-index: 0;
}
.worker-card .color-box i.block[data-v-3e7764e6] {
  top: 18px;
  right: 18px;
  font-size: 25px;
  cursor: not-allowed;
  transition: 0.25s;
  position: absolute;
  display: none;
}
.worker-card .color-box i.block[data-v-3e7764e6]:hover {
  color: #EB5757;
}
.worker-card .avatar[data-v-3e7764e6] {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin: 37.5px auto 0;
  overflow: hidden;
  border: 5px solid #1F2124;
  background-color: #8D9396;
  box-sizing: content-box;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.worker-card .avatar img[data-v-3e7764e6] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.worker-card .name[data-v-3e7764e6] {
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: bold;
  color: #bfbfbf;
}
.worker-card .position[data-v-3e7764e6] {
  margin-bottom: 0;
}
.worker-card .branch[data-v-3e7764e6] {
  margin-top: 8px;
  margin-bottom: 0;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  display: inline-block;
  font-weight: bold;
  background-color: #595A5A;
  color: #fafafa;
}
.worker-card .actionsContainer[data-v-3e7764e6] {
  width: 100%;
  display: flex;
  flex-direction: row;
  bottom: 5px;
  position: absolute;
  justify-content: center;
}
.worker-card .actionsContainer .action-btn[data-v-3e7764e6] {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}
.worker-card .actionsContainer .action-btn i[data-v-3e7764e6] {
  font-size: 20px;
  color: #595959;
}
