.device[data-v-e9bbe255] {
  height: 60px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #45474A;
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  overflow: hidden;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.device .info[data-v-e9bbe255] {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.device .info div[data-v-e9bbe255]:first-child {
  display: flex;
  align-items: flex-end;
}
.device .icon-wrapper[data-v-e9bbe255] {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.device .icon-wrapper .browser-icon[data-v-e9bbe255] {
  overflow: hidden;
}
.device .icon-wrapper.chrome[data-v-e9bbe255] {
  padding: 15px;
}
.device .icon-wrapper.brave[data-v-e9bbe255] {
  padding: 15px;
}
.device .unknown-agent[data-v-e9bbe255] {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  color: #d9d9d9;
  background: #595A5A;
}
.device .block[data-v-e9bbe255] {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background-color: #EB5757;
  cursor: pointer;
  transition: 0.25s;
}
.device .block[data-v-e9bbe255]:hover {
  background-color: #F78783;
}
