.branch-card[data-v-5c1d79a7] {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.branch-card .address[data-v-5c1d79a7] {
  font-size: 16px;
  font-weight: bold;
}
.branch-card .timer[data-v-5c1d79a7] {
  width: 100%;
  height: 8px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  grid-area: timer;
  overflow: hidden;
}
.branch-card .timer .progress[data-v-5c1d79a7] {
  width: 0;
  height: 100%;
  border-radius: 0 8px 8px 0;
  transition: 0.25s;
}
.branch-card .colorLabel[data-v-5c1d79a7] {
  top: -25px;
  right: -25px;
  width: 50px;
  height: 50px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
}
