@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-65b2bef1] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-65b2bef1] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-65b2bef1] {
  width: 100vw;
  height: 100vh;
}
div[data-v-65b2bef1] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-65b2bef1],
p[data-v-65b2bef1],
span[data-v-65b2bef1],
h1[data-v-65b2bef1],
h2[data-v-65b2bef1],
h3[data-v-65b2bef1],
h4[data-v-65b2bef1],
h5[data-v-65b2bef1],
h6[data-v-65b2bef1],
button[data-v-65b2bef1],
input[data-v-65b2bef1],
textarea[data-v-65b2bef1],
select[data-v-65b2bef1] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-65b2bef1] {
  color: #d9d9d9;
}
a[data-v-65b2bef1] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-65b2bef1] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-65b2bef1]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-65b2bef1]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-65b2bef1]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-65b2bef1] {
  touch-action: pan-y;
}
.account[data-v-65b2bef1] {
  max-height: 100vh;
  overflow-y: auto;
}
.account .profile[data-v-65b2bef1] {
  height: 280px;
  background-color: #313438;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-x: 50%;
  background-position-y: 6%;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  overflow: hidden;
@include boxShadow();
}
.account .profile .circles[data-v-65b2bef1] {
  width: 105%;
  min-width: 1920px;
  top: 0;
  position: absolute;
}
.account .profile .circles path[data-v-65b2bef1] {
  opacity: 0.2;
}
.account .profile .wrapper[data-v-65b2bef1] {
  max-width: 500px;
  margin: 0 auto;
  padding: 60px 20px 20px;
  position: relative;
}
.account .profile .wrapper .burger[data-v-65b2bef1] {
  top: 15.5px;
  left: 15.5px;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 3px;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  position: absolute;
  cursor: pointer;
}
.account .profile .wrapper .burger li[data-v-65b2bef1] {
  width: 25px;
  height: 3px;
  border-radius: 5px;
  background-color: #f5f5f5;
  list-style: none;
  transition: 0.25s;
}
.account .profile .wrapper .burger.close[data-v-65b2bef1] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.account .profile .wrapper .burger.close li[data-v-65b2bef1]:nth-child(2) {
  display: none;
}
.account .profile .wrapper .burger.close li[data-v-65b2bef1]:first-child,
.account .profile .wrapper .burger.close li[data-v-65b2bef1]:last-child {
  width: 22px;
  height: 3px;
  position: absolute;
  content: '';
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #f5f5f5;
  transition: 0.25s;
}
.account .profile .wrapper .burger.close li[data-v-65b2bef1]:first-child {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.account .profile .wrapper .QRScannerButton[data-v-65b2bef1] {
  top: 15.5px;
  right: 15.5px;
  width: 45px;
  height: 45px;
  padding: 12px;
  position: absolute;
  fill: #f5f5f5;
  cursor: pointer;
}
.account .profile .wrapper .photoAndRating[data-v-65b2bef1] {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
.account .profile .wrapper .photoAndRating .photo[data-v-65b2bef1] {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background-color: #595A5A;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.account .profile .wrapper .photoAndRating .photo img[data-v-65b2bef1] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.account .profile .wrapper .photoAndRating .level[data-v-65b2bef1],
.account .profile .wrapper .photoAndRating .tasks[data-v-65b2bef1] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.account .profile .wrapper .photoAndRating .level p[data-v-65b2bef1],
.account .profile .wrapper .photoAndRating .tasks p[data-v-65b2bef1] {
  margin: 0;
  font-size: 16px;
  color: #d9d9d9;
}
.account .profile .wrapper .photoAndRating .level p[data-v-65b2bef1]:first-child,
.account .profile .wrapper .photoAndRating .tasks p[data-v-65b2bef1]:first-child {
  font-size: 28px;
  color: #d9d9d9;
  font-weight: bold;
}
.account .profile .wrapper .nameAndPosition[data-v-65b2bef1] {
  text-align: center;
}
.account .profile .wrapper .nameAndPosition p[data-v-65b2bef1] {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #d9d9d9;
  -webkit-user-select: text;
          user-select: text;
}
.account .profile .wrapper .nameAndPosition p[data-v-65b2bef1]:first-child {
  margin-top: 10px;
  font-size: 26px;
  color: #fafafa;
  font-weight: bold;
}
.account .control[data-v-65b2bef1] {
  -webkit-user-select: none;
          user-select: none;
}
.account .control .wrapper[data-v-65b2bef1] {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}
.account .control .wrapper .control-section[data-v-65b2bef1] {
  margin-bottom: 30px;
}
.account .control .wrapper .control-section .section-title[data-v-65b2bef1] {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: lighter;
  color: #8c8c8c;
}
.account .control .wrapper .control-section .control-elem[data-v-65b2bef1] {
  height: 30px;
  margin-bottom: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  box-sizing: content-box;
  cursor: pointer;
}
.account .control .wrapper .control-section .control-elem .fa-chevron-right[data-v-65b2bef1] {
  color: #d9d9d9;
}
.account .control .wrapper .control-section .control-elem.disabled[data-v-65b2bef1] {
  cursor: not-allowed;
}
.account .control .wrapper .control-section .control-elem.disabled .fa-chevron-right[data-v-65b2bef1] {
  color: #434343;
}
.account .control .wrapper .control-section .control-elem.disabled .controlName[data-v-65b2bef1] {
  color: #434343;
}
.account .control .wrapper .logOut[data-v-65b2bef1] {
  width: 100%;
  height: 50px;
  margin-top: 25px;
  border: none;
  border-radius: 10px;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  transition: 0.25s;
  background-color: #EB5757;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.account .control .wrapper .logOut[data-v-65b2bef1]:hover {
  background-color: #F78783;
}
/* Landscape phones and smaller */
@media (max-width: 480px) {
.hideOnSmallScreens[data-v-65b2bef1] {
    display: none;
}
.branch .grid .selectors .circlesContainer[data-v-65b2bef1],
  .pos-statistics .grid .selectors .circlesContainer[data-v-65b2bef1] {
    grid-template-columns: 1fr;
}
.tickets .selectors[data-v-65b2bef1] {
    grid-template-columns: 1fr;
}
.tickets .selectors .complited-selector[data-v-65b2bef1] {
    margin-left: 15px;
    margin-right: 15px;
}
.tickets .selectors .branches-selector[data-v-65b2bef1] {
    padding-left: 15px;
}
}
/* Portrait tablets and small desktops */
@media (max-width: 768px) {
.logIn.scene .login-form[data-v-65b2bef1] {
    padding-top: 50px;
    bottom: 0;
    width: 100vw;
    height: 400px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: absolute;
    background-color: #101012;
}
.logIn.scene .logo[data-v-65b2bef1] {
    left: 0;
    width: 100vw;
    padding-left: 0;
    position: absolute;
}
.logIn.scene .circles[data-v-65b2bef1] {
    display: block;
}
.logIn.scene .tree[data-v-65b2bef1] {
    display: none;
}
.logIn.scene .build[data-v-65b2bef1] {
    left: 0;
    width: 100vw;
    text-align: center;
    z-index: 3;
}
}
@media (min-width: 768px) {
.stage > .stage-content > .title-bar > .actions .search[data-v-65b2bef1] {
    display: grid;
}
.stage > .stage-content > .title-bar > .actions .top-nav-bar-search-action-button[data-v-65b2bef1] {
    display: none;
}
.workers.scene .sceneTitle[data-v-65b2bef1] {
    grid-template-columns: 1fr auto;
}
.scene[data-v-65b2bef1] {
    grid-area: stage;
}
.CCTV .grid[data-v-65b2bef1],
  .CCTVView .grid[data-v-65b2bef1],
  .branchs .grid[data-v-65b2bef1],
  .home .grid[data-v-65b2bef1],
  .lern .grid[data-v-65b2bef1],
  .network .grid[data-v-65b2bef1],
  .tickets .grid[data-v-65b2bef1] {
    grid-template-columns: repeat(2, 1fr);
}
.branch[data-v-65b2bef1],
  .pos-statistics[data-v-65b2bef1] {
    display: flex;
}
.branch .grid[data-v-65b2bef1],
  .pos-statistics .grid[data-v-65b2bef1] {
    overflow-y: auto;
    grid-template-columns: auto 1fr;
}
.branch .grid .selectors[data-v-65b2bef1],
  .pos-statistics .grid .selectors[data-v-65b2bef1] {
    padding-top: 0;
    overflow-y: auto;
}
.branch .grid .data[data-v-65b2bef1],
  .pos-statistics .grid .data[data-v-65b2bef1] {
    overflow-y: auto;
}
.workers.scene .profiles[data-v-65b2bef1] {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
}
@media (max-width: 991px) {
.navBar[data-v-65b2bef1] {
    width: 200px;
}
.navBar .back-button[data-v-65b2bef1] {
    width: 150px;
}
.navBar .back-button span[data-v-65b2bef1] {
    display: block;
}
.navBar .buttonsContainer[data-v-65b2bef1] {
    width: 100%;
}
.navBar .buttonsContainer .navLinkButton[data-v-65b2bef1] {
    width: 150px;
    justify-content: flex-start;
}
.navBar .buttonsContainer .navLinkButton span[data-v-65b2bef1] {
    display: block;
}
.navBar .account-wrapper[data-v-65b2bef1] {
    width: 100%;
}
.navBar .account-wrapper .account[data-v-65b2bef1] {
    width: 150px;
    grid-template-columns: 50px auto;
}
.navBar .account-wrapper .account span[data-v-65b2bef1] {
    display: flex;
}
}
/* Portrait tablets and medium desktops */
@media (min-width: 992px) {
.navBar .buttonsContainer .navLinkButton.selected .marker[data-v-65b2bef1] {
    display: none;
}
.account.scene > .profile > .wrapper .burger[data-v-65b2bef1] {
    display: none;
}
.stage .stage-content[data-v-65b2bef1] {
    width: calc(100vw - 80px);
    box-shadow: none;
    left: 80px;
}
.stage .stage-content .title-bar[data-v-65b2bef1] {
    padding-left: 15px;
    grid-template-columns: 1fr auto;
}
.stage .stage-content .title-bar .burger[data-v-65b2bef1] {
    display: none;
}
.check-editor.scene[data-v-65b2bef1] {
    overflow: hidden;
}
.check-editor.scene .container[data-v-65b2bef1] {
    grid-template-columns: auto 1fr;
}
.check-editor.scene .container .editor[data-v-65b2bef1] {
    width: 400px;
}
.logIn.scene .tree[data-v-65b2bef1] {
    width: 850px;
    right: 25px;
}
.workers.scene .profiles[data-v-65b2bef1] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
}
/* Large desktops and laptops */
@media (min-width: 1200px) {
.navBar .buttonsContainer .navLinkButton.selected .marker[data-v-65b2bef1] {
    display: block;
}
.stage .stage-content[data-v-65b2bef1] {
    width: calc(100vw - 200px);
    box-shadow: none;
    left: 200px;
}
.stage .back-button[data-v-65b2bef1] {
    width: 150px;
}
.stage .back-button span[data-v-65b2bef1] {
    display: block;
}
.navBar[data-v-65b2bef1] {
    width: 200px;
}
.navBar .buttonsContainer[data-v-65b2bef1] {
    width: 100%;
}
.navBar .buttonsContainer .navLinkButton[data-v-65b2bef1] {
    width: 150px;
    justify-content: flex-start;
}
.navBar .buttonsContainer .navLinkButton span[data-v-65b2bef1] {
    display: block;
}
.navBar .account-wrapper[data-v-65b2bef1] {
    width: 100%;
}
.navBar .account-wrapper .account[data-v-65b2bef1] {
    width: 150px;
    grid-template-columns: 50px auto;
}
.navBar .account-wrapper .account span[data-v-65b2bef1] {
    display: flex;
}
.profile .baseInfoWrapper[data-v-65b2bef1] {
    grid-template-columns: auto 1fr;
}
.profile .baseInfoWrapper .name[data-v-65b2bef1] {
    font-size: 30px;
}
.CCTV .grid[data-v-65b2bef1],
  .CCTVView .grid[data-v-65b2bef1],
  .branchs .grid[data-v-65b2bef1],
  .home .grid[data-v-65b2bef1],
  .lern .grid[data-v-65b2bef1],
  .network .grid[data-v-65b2bef1],
  .tickets .grid[data-v-65b2bef1] {
    max-width: 1250px;
    grid-template-columns: repeat(3, 1fr);
}
.workers.scene .profiles[data-v-65b2bef1] {
    display: flex;
    flex-wrap: wrap;
}
}
@media (min-width: 1600px) {
.CCTV .grid[data-v-65b2bef1],
  .CCTVView .grid[data-v-65b2bef1],
  .branchs .grid[data-v-65b2bef1],
  .home .grid[data-v-65b2bef1],
  .lern .grid[data-v-65b2bef1],
  .network .grid[data-v-65b2bef1],
  .tickets .grid[data-v-65b2bef1],
  .workers .grid[data-v-65b2bef1] {
    max-width: 1800px;
    grid-template-columns: repeat(4, 1fr);
}
}
@media (min-width: 1700px) {
.network .grid[data-v-65b2bef1] {
    max-width: 1800px;
    grid-template-columns: repeat(5, 1fr);
}
}
