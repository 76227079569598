@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-131a75d2] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-131a75d2] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-131a75d2] {
  width: 100vw;
  height: 100vh;
}
div[data-v-131a75d2] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-131a75d2],
p[data-v-131a75d2],
span[data-v-131a75d2],
h1[data-v-131a75d2],
h2[data-v-131a75d2],
h3[data-v-131a75d2],
h4[data-v-131a75d2],
h5[data-v-131a75d2],
h6[data-v-131a75d2],
button[data-v-131a75d2],
input[data-v-131a75d2],
textarea[data-v-131a75d2],
select[data-v-131a75d2] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-131a75d2] {
  color: #d9d9d9;
}
a[data-v-131a75d2] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-131a75d2] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-131a75d2]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-131a75d2]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-131a75d2]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-131a75d2] {
  touch-action: pan-y;
  margin: 0 auto;
  padding: 15px 15px 15px 15px;
  height: calc(100vh - 45px);
  overflow-y: auto;
}
.scene .grid[data-v-131a75d2] {
  margin: 0 auto;
}
.scene.mx-lim[data-v-131a75d2] {
  max-width: 1000px;
}
.home[data-v-131a75d2] {
  max-height: 100%;
  align-items: center;
  overflow-y: auto;
}
.home .grid[data-v-131a75d2] {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding-bottom: 20px;
  justify-content: start;
}
.home .grid .BranchCard[data-v-131a75d2] {
  height: auto;
}
/* Landscape phones and smaller */
@media (max-width: 480px) {
.hideOnSmallScreens[data-v-131a75d2] {
    display: none;
}
.branch .grid .selectors .circlesContainer[data-v-131a75d2],
  .pos-statistics .grid .selectors .circlesContainer[data-v-131a75d2] {
    grid-template-columns: 1fr;
}
.tickets .selectors[data-v-131a75d2] {
    grid-template-columns: 1fr;
}
.tickets .selectors .complited-selector[data-v-131a75d2] {
    margin-left: 15px;
    margin-right: 15px;
}
.tickets .selectors .branches-selector[data-v-131a75d2] {
    padding-left: 15px;
}
}
/* Portrait tablets and small desktops */
@media (max-width: 768px) {
.logIn.scene .login-form[data-v-131a75d2] {
    padding-top: 50px;
    bottom: 0;
    width: 100vw;
    height: 400px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: absolute;
    background-color: #101012;
}
.logIn.scene .logo[data-v-131a75d2] {
    left: 0;
    width: 100vw;
    padding-left: 0;
    position: absolute;
}
.logIn.scene .circles[data-v-131a75d2] {
    display: block;
}
.logIn.scene .tree[data-v-131a75d2] {
    display: none;
}
.logIn.scene .build[data-v-131a75d2] {
    left: 0;
    width: 100vw;
    text-align: center;
    z-index: 3;
}
}
@media (min-width: 768px) {
.stage > .stage-content > .title-bar > .actions .search[data-v-131a75d2] {
    display: grid;
}
.stage > .stage-content > .title-bar > .actions .top-nav-bar-search-action-button[data-v-131a75d2] {
    display: none;
}
.workers.scene .sceneTitle[data-v-131a75d2] {
    grid-template-columns: 1fr auto;
}
.scene[data-v-131a75d2] {
    grid-area: stage;
}
.CCTV .grid[data-v-131a75d2],
  .CCTVView .grid[data-v-131a75d2],
  .branchs .grid[data-v-131a75d2],
  .home .grid[data-v-131a75d2],
  .lern .grid[data-v-131a75d2],
  .network .grid[data-v-131a75d2],
  .tickets .grid[data-v-131a75d2] {
    grid-template-columns: repeat(2, 1fr);
}
.branch[data-v-131a75d2],
  .pos-statistics[data-v-131a75d2] {
    display: flex;
}
.branch .grid[data-v-131a75d2],
  .pos-statistics .grid[data-v-131a75d2] {
    overflow-y: auto;
    grid-template-columns: auto 1fr;
}
.branch .grid .selectors[data-v-131a75d2],
  .pos-statistics .grid .selectors[data-v-131a75d2] {
    padding-top: 0;
    overflow-y: auto;
}
.branch .grid .data[data-v-131a75d2],
  .pos-statistics .grid .data[data-v-131a75d2] {
    overflow-y: auto;
}
.workers.scene .profiles[data-v-131a75d2] {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
}
@media (max-width: 991px) {
.navBar[data-v-131a75d2] {
    width: 200px;
}
.navBar .back-button[data-v-131a75d2] {
    width: 150px;
}
.navBar .back-button span[data-v-131a75d2] {
    display: block;
}
.navBar .buttonsContainer[data-v-131a75d2] {
    width: 100%;
}
.navBar .buttonsContainer .navLinkButton[data-v-131a75d2] {
    width: 150px;
    justify-content: flex-start;
}
.navBar .buttonsContainer .navLinkButton span[data-v-131a75d2] {
    display: block;
}
.navBar .account-wrapper[data-v-131a75d2] {
    width: 100%;
}
.navBar .account-wrapper .account[data-v-131a75d2] {
    width: 150px;
    grid-template-columns: 50px auto;
}
.navBar .account-wrapper .account span[data-v-131a75d2] {
    display: flex;
}
}
/* Portrait tablets and medium desktops */
@media (min-width: 992px) {
.navBar .buttonsContainer .navLinkButton.selected .marker[data-v-131a75d2] {
    display: none;
}
.account.scene > .profile > .wrapper .burger[data-v-131a75d2] {
    display: none;
}
.stage .stage-content[data-v-131a75d2] {
    width: calc(100vw - 80px);
    box-shadow: none;
    left: 80px;
}
.stage .stage-content .title-bar[data-v-131a75d2] {
    padding-left: 15px;
    grid-template-columns: 1fr auto;
}
.stage .stage-content .title-bar .burger[data-v-131a75d2] {
    display: none;
}
.check-editor.scene[data-v-131a75d2] {
    overflow: hidden;
}
.check-editor.scene .container[data-v-131a75d2] {
    grid-template-columns: auto 1fr;
}
.check-editor.scene .container .editor[data-v-131a75d2] {
    width: 400px;
}
.logIn.scene .tree[data-v-131a75d2] {
    width: 850px;
    right: 25px;
}
.workers.scene .profiles[data-v-131a75d2] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
}
/* Large desktops and laptops */
@media (min-width: 1200px) {
.navBar .buttonsContainer .navLinkButton.selected .marker[data-v-131a75d2] {
    display: block;
}
.stage .stage-content[data-v-131a75d2] {
    width: calc(100vw - 200px);
    box-shadow: none;
    left: 200px;
}
.stage .back-button[data-v-131a75d2] {
    width: 150px;
}
.stage .back-button span[data-v-131a75d2] {
    display: block;
}
.navBar[data-v-131a75d2] {
    width: 200px;
}
.navBar .buttonsContainer[data-v-131a75d2] {
    width: 100%;
}
.navBar .buttonsContainer .navLinkButton[data-v-131a75d2] {
    width: 150px;
    justify-content: flex-start;
}
.navBar .buttonsContainer .navLinkButton span[data-v-131a75d2] {
    display: block;
}
.navBar .account-wrapper[data-v-131a75d2] {
    width: 100%;
}
.navBar .account-wrapper .account[data-v-131a75d2] {
    width: 150px;
    grid-template-columns: 50px auto;
}
.navBar .account-wrapper .account span[data-v-131a75d2] {
    display: flex;
}
.profile .baseInfoWrapper[data-v-131a75d2] {
    grid-template-columns: auto 1fr;
}
.profile .baseInfoWrapper .name[data-v-131a75d2] {
    font-size: 30px;
}
.CCTV .grid[data-v-131a75d2],
  .CCTVView .grid[data-v-131a75d2],
  .branchs .grid[data-v-131a75d2],
  .home .grid[data-v-131a75d2],
  .lern .grid[data-v-131a75d2],
  .network .grid[data-v-131a75d2],
  .tickets .grid[data-v-131a75d2] {
    max-width: 1250px;
    grid-template-columns: repeat(3, 1fr);
}
.workers.scene .profiles[data-v-131a75d2] {
    display: flex;
    flex-wrap: wrap;
}
}
@media (min-width: 1600px) {
.CCTV .grid[data-v-131a75d2],
  .CCTVView .grid[data-v-131a75d2],
  .branchs .grid[data-v-131a75d2],
  .home .grid[data-v-131a75d2],
  .lern .grid[data-v-131a75d2],
  .network .grid[data-v-131a75d2],
  .tickets .grid[data-v-131a75d2],
  .workers .grid[data-v-131a75d2] {
    max-width: 1800px;
    grid-template-columns: repeat(4, 1fr);
}
}
@media (min-width: 1700px) {
.network .grid[data-v-131a75d2] {
    max-width: 1800px;
    grid-template-columns: repeat(5, 1fr);
}
}
