@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-f219da71] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-f219da71] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-f219da71] {
  width: 100vw;
  height: 100vh;
}
div[data-v-f219da71] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-f219da71],
p[data-v-f219da71],
span[data-v-f219da71],
h1[data-v-f219da71],
h2[data-v-f219da71],
h3[data-v-f219da71],
h4[data-v-f219da71],
h5[data-v-f219da71],
h6[data-v-f219da71],
button[data-v-f219da71],
input[data-v-f219da71],
textarea[data-v-f219da71],
select[data-v-f219da71] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-f219da71] {
  color: #d9d9d9;
}
a[data-v-f219da71] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-f219da71] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-f219da71]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-f219da71]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-f219da71]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-f219da71] {
  touch-action: pan-y;
}
.pos-order-card[data-v-f219da71] {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  background-color: #1F2124;
  cursor: pointer;
  transition: 0.25s;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.pos-order-card .order-info[data-v-f219da71] {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pos-order-card .order-info .order-branch-id .branch-name[data-v-f219da71] {
  font-weight: bold;
}
.pos-order-card .order-info .order-branch-id .order-id[data-v-f219da71] {
  color: #75797D;
  transition: 0.25s;
}
.pos-order-card .order-info .order-branch-id .order-id[data-v-f219da71]:hover {
  color: #A5ACB0;
}
.pos-order-card .order-info .order-dates[data-v-f219da71] {
  display: flex;
  grid-gap: 10px;
  font-weight: bold;
}
.pos-order-card .order-info .order-dates .order-life-time[data-v-f219da71],
.pos-order-card .order-info .order-dates .order-date[data-v-f219da71] {
  color: #81868A;
  transition: 0.25s;
}
.pos-order-card .order-info .order-dates .order-life-time[data-v-f219da71] {
  color: #9ACD96;
  transition: 0.25s;
}
.pos-order-card .order-info .order-dates .order-life-time.warning[data-v-f219da71] {
  color: #F2D66F;
  transition: 0.25s;
}
.pos-order-card .order-info .order-dates .order-life-time.error[data-v-f219da71] {
  color: #F78783;
  transition: 0.25s;
}
.pos-order-card .order-info .order-dates .order-date[data-v-f219da71]:hover {
  color: #A5ACB0;
}
.pos-order-card .items[data-v-f219da71] {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  margin-top: 10px;
}
.pos-order-card .items .order-item[data-v-f219da71] {
  border-radius: 5px;
  background-color: #313438;
  overflow: hidden;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.pos-order-card .items .order-item .item-parts[data-v-f219da71] {
  padding: 10px;
  grid-gap: 10px;
  display: flex;
  flex-wrap: wrap;
}
.pos-order-card .items .order-item .item-parts .item-part[data-v-f219da71]:first-child {
  min-width: 260px;
}
.pos-order-card .items .order-item .item-parts .item-part:first-child .label[data-v-f219da71],
.pos-order-card .items .order-item .item-parts .item-part:first-child .value[data-v-f219da71] {
  text-align: left;
}
.pos-order-card .items .order-item .item-parts .item-part[data-v-f219da71] {
  display: inline-flex;
  flex-direction: column;
}
.pos-order-card .items .order-item .item-parts .item-part .label[data-v-f219da71],
.pos-order-card .items .order-item .item-parts .item-part .value[data-v-f219da71] {
  text-align: center;
}
.pos-order-card .items .order-item .item-parts .item-part .value.error[data-v-f219da71] {
  color: #F78783;
}
.pos-order-card .items .order-item .item-parts .item-part .value.warning[data-v-f219da71] {
  color: #F2D66F;
}
.pos-order-card .items .order-item .item-parts .item-part .label[data-v-f219da71] {
  font-size: 14px;
  font-weight: lighter;
  font-style: italic;
  color: #81868A;
}
.pos-order-card .items .order-item .item-parts .item-part .value.station-hot[data-v-f219da71] {
  color: #F78783;
}
.pos-order-card .items .order-item .item-parts .item-part .value.station-cold[data-v-f219da71] {
  color: #40a9ff;
}
.pos-order-card .items .order-item .item-parts .item-part .value > i.fas.fa-flag[data-v-f219da71] {
  color: #9ACD96;
}
.pos-order-card .items .order-item .modifiers[data-v-f219da71] {
  display: flex;
  flex-direction: column;
}
.pos-order-card .items .order-item .modifiers span[data-v-f219da71] {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #40a9ff;
  background-color: #45474A;
}
.pos-order-card .items .order-item .modifiers span[data-v-f219da71]:nth-child(2n) {
  background-color: #595A5A;
}
.pos-order-card[data-v-f219da71]:hover {
  box-shadow: inset 0 0 50px rgba(69, 71, 74, 0.3);
}
