@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-f8f0e13f] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-f8f0e13f] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-f8f0e13f] {
  width: 100vw;
  height: 100vh;
}
div[data-v-f8f0e13f] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-f8f0e13f],
p[data-v-f8f0e13f],
span[data-v-f8f0e13f],
h1[data-v-f8f0e13f],
h2[data-v-f8f0e13f],
h3[data-v-f8f0e13f],
h4[data-v-f8f0e13f],
h5[data-v-f8f0e13f],
h6[data-v-f8f0e13f],
button[data-v-f8f0e13f],
input[data-v-f8f0e13f],
textarea[data-v-f8f0e13f],
select[data-v-f8f0e13f] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-f8f0e13f] {
  color: #d9d9d9;
}
a[data-v-f8f0e13f] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-f8f0e13f] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-f8f0e13f]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-f8f0e13f]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-f8f0e13f]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-f8f0e13f] {
  touch-action: pan-y;
}
.CameraCard[data-v-f8f0e13f] {
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-color: #141414;
}
.CameraCard .cam-preview[data-v-f8f0e13f] {
  width: 100%;
}
.CameraCard .cam-card-overlay[data-v-f8f0e13f] {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 10%, rgba(0, 0, 0, 0) 30%);
}
.CameraCard .cam-card-overlay .cam-name[data-v-f8f0e13f],
.CameraCard .cam-card-overlay .cam-live-status[data-v-f8f0e13f] {
  bottom: 4px;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
}
.CameraCard .cam-card-overlay .cam-name[data-v-f8f0e13f] {
  left: 10px;
  display: flex;
}
.CameraCard .cam-card-overlay .cam-live-status[data-v-f8f0e13f] {
  right: 10px;
  justify-content: center;
}
.CameraCard .cam-card-overlay .cam-live-status span[data-v-f8f0e13f] {
  margin-right: 5px;
}
.CameraCard .cam-card-overlay .cam-live-status i[data-v-f8f0e13f] {
  color: #F78783;
}
