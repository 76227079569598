@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-5d4d6b03] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-5d4d6b03] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-5d4d6b03] {
  width: 100vw;
  height: 100vh;
}
div[data-v-5d4d6b03] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-5d4d6b03],
p[data-v-5d4d6b03],
span[data-v-5d4d6b03],
h1[data-v-5d4d6b03],
h2[data-v-5d4d6b03],
h3[data-v-5d4d6b03],
h4[data-v-5d4d6b03],
h5[data-v-5d4d6b03],
h6[data-v-5d4d6b03],
button[data-v-5d4d6b03],
input[data-v-5d4d6b03],
textarea[data-v-5d4d6b03],
select[data-v-5d4d6b03] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-5d4d6b03] {
  color: #d9d9d9;
}
a[data-v-5d4d6b03] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-5d4d6b03] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-5d4d6b03]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-5d4d6b03]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-5d4d6b03]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-5d4d6b03] {
  touch-action: pan-y;
}
.center-containter[data-v-5d4d6b03] {
  width: 100%;
  max-width: 400px;
  max-height: 100vh;
  overflow: hidden;
  padding: 15px;
}
.center-containter .options-list[data-v-5d4d6b03] {
  width: 100%;
  max-height: calc(100vh - 30px);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  border-radius: 15px;
  overflow: auto;
}
.center-containter .options-list .option[data-v-5d4d6b03] {
  width: 100%;
  padding: 15px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  background: #313438;
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.center-containter .options-list .option .select-sing[data-v-5d4d6b03] {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 2px solid #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.center-containter .options-list .option .select-sing.active[data-v-5d4d6b03] {
  border-color: #40a9ff;
}
.center-containter .options-list .option .select-sing.active[data-v-5d4d6b03]:after {
  width: 8px;
  height: 8px;
  content: "";
  border-radius: 100%;
  background: #40a9ff;
}
.center-containter .options-list .option[data-v-5d4d6b03]:last-child {
  border: none;
}
