.stage::before {
  content: 'Main';
  top: -35px;
  font-size: 24px;
  color: #A5ACB0;
  position: absolute;
  z-index: 10;
}
.stage-content {
  width: 100vw;
  height: 100vh;
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.6);
  position: absolute;
  overflow: hidden;
}
.stage-content .title-bar .actions .top-nav-bar-button,
.stage-content .title-bar .actions .top-nav-bar-search-action-button {
  font-size: 20px;
  margin-left: 10px;
}
.stage-content .title-bar .actions .top-nav-bar-search-action-button {
  margin-left: 4px;
}
.stage-content .title-bar .actions .top-nav-bar-button.fa-save {
  color: #8c8c8c;
}
.stage-content .title-bar .actions .top-nav-bar-button.fa-save.current {
  color: #f0f0f0;
}
.stage-content .title-bar .actions .fa-trash.top-nav-bar-button {
  color: #8c8c8c;
}
.stage-content .title-bar .actions .fa-trash.top-nav-bar-button.exit {
  color: #f0f0f0;
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0 {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root {
  width: 100vw;
  height: 100vh;
}
div {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
textarea,
select {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i {
  color: #d9d9d9;
}
a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
* {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene {
  touch-action: pan-y;
}
/* Landscape phones and smaller */
@media (max-width: 480px) {
  .hideOnSmallScreens {
    display: none;
  }
  .branch .grid .selectors .circlesContainer,
  .pos-statistics .grid .selectors .circlesContainer {
    grid-template-columns: 1fr;
  }
  .tickets .selectors {
    grid-template-columns: 1fr;
  }
  .tickets .selectors .complited-selector {
    margin-left: 15px;
    margin-right: 15px;
  }
  .tickets .selectors .branches-selector {
    padding-left: 15px;
  }
}
/* Portrait tablets and small desktops */
@media (max-width: 768px) {
  .logIn.scene .login-form {
    padding-top: 50px;
    bottom: 0;
    width: 100vw;
    height: 400px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: absolute;
    background-color: #101012;
  }
  .logIn.scene .logo {
    left: 0;
    width: 100vw;
    padding-left: 0;
    position: absolute;
  }
  .logIn.scene .circles {
    display: block;
  }
  .logIn.scene .tree {
    display: none;
  }
  .logIn.scene .build {
    left: 0;
    width: 100vw;
    text-align: center;
    z-index: 3;
  }
}
@media (min-width: 768px) {
  .stage > .stage-content > .title-bar > .actions .search {
    display: grid;
  }
  .stage > .stage-content > .title-bar > .actions .top-nav-bar-search-action-button {
    display: none;
  }
  .workers.scene .sceneTitle {
    grid-template-columns: 1fr auto;
  }
  .scene {
    grid-area: stage;
  }
  .CCTV .grid,
  .CCTVView .grid,
  .branchs .grid,
  .home .grid,
  .lern .grid,
  .network .grid,
  .tickets .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .branch,
  .pos-statistics {
    display: flex;
  }
  .branch .grid,
  .pos-statistics .grid {
    overflow-y: auto;
    grid-template-columns: auto 1fr;
  }
  .branch .grid .selectors,
  .pos-statistics .grid .selectors {
    padding-top: 0;
    overflow-y: auto;
  }
  .branch .grid .data,
  .pos-statistics .grid .data {
    overflow-y: auto;
  }
  .workers.scene .profiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 991px) {
  .navBar {
    width: 200px;
  }
  .navBar .back-button {
    width: 150px;
  }
  .navBar .back-button span {
    display: block;
  }
  .navBar .buttonsContainer {
    width: 100%;
  }
  .navBar .buttonsContainer .navLinkButton {
    width: 150px;
    justify-content: flex-start;
  }
  .navBar .buttonsContainer .navLinkButton span {
    display: block;
  }
  .navBar .account-wrapper {
    width: 100%;
  }
  .navBar .account-wrapper .account {
    width: 150px;
    grid-template-columns: 50px auto;
  }
  .navBar .account-wrapper .account span {
    display: flex;
  }
}
/* Portrait tablets and medium desktops */
@media (min-width: 992px) {
  .navBar .buttonsContainer .navLinkButton.selected .marker {
    display: none;
  }
  .account.scene > .profile > .wrapper .burger {
    display: none;
  }
  .stage .stage-content {
    width: calc(100vw - 80px);
    box-shadow: none;
    left: 80px;
  }
  .stage .stage-content .title-bar {
    padding-left: 15px;
    grid-template-columns: 1fr auto;
  }
  .stage .stage-content .title-bar .burger {
    display: none;
  }
  .check-editor.scene {
    overflow: hidden;
  }
  .check-editor.scene .container {
    grid-template-columns: auto 1fr;
  }
  .check-editor.scene .container .editor {
    width: 400px;
  }
  .logIn.scene .tree {
    width: 850px;
    right: 25px;
  }
  .workers.scene .profiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
/* Large desktops and laptops */
@media (min-width: 1200px) {
  .navBar .buttonsContainer .navLinkButton.selected .marker {
    display: block;
  }
  .stage .stage-content {
    width: calc(100vw - 200px);
    box-shadow: none;
    left: 200px;
  }
  .stage .back-button {
    width: 150px;
  }
  .stage .back-button span {
    display: block;
  }
  .navBar {
    width: 200px;
  }
  .navBar .buttonsContainer {
    width: 100%;
  }
  .navBar .buttonsContainer .navLinkButton {
    width: 150px;
    justify-content: flex-start;
  }
  .navBar .buttonsContainer .navLinkButton span {
    display: block;
  }
  .navBar .account-wrapper {
    width: 100%;
  }
  .navBar .account-wrapper .account {
    width: 150px;
    grid-template-columns: 50px auto;
  }
  .navBar .account-wrapper .account span {
    display: flex;
  }
  .profile .baseInfoWrapper {
    grid-template-columns: auto 1fr;
  }
  .profile .baseInfoWrapper .name {
    font-size: 30px;
  }
  .CCTV .grid,
  .CCTVView .grid,
  .branchs .grid,
  .home .grid,
  .lern .grid,
  .network .grid,
  .tickets .grid {
    max-width: 1250px;
    grid-template-columns: repeat(3, 1fr);
  }
  .workers.scene .profiles {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 1600px) {
  .CCTV .grid,
  .CCTVView .grid,
  .branchs .grid,
  .home .grid,
  .lern .grid,
  .network .grid,
  .tickets .grid,
  .workers .grid {
    max-width: 1800px;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1700px) {
  .network .grid {
    max-width: 1800px;
    grid-template-columns: repeat(5, 1fr);
  }
}
