@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-d740ac6c] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-d740ac6c] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-d740ac6c] {
  width: 100vw;
  height: 100vh;
}
div[data-v-d740ac6c] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-d740ac6c],
p[data-v-d740ac6c],
span[data-v-d740ac6c],
h1[data-v-d740ac6c],
h2[data-v-d740ac6c],
h3[data-v-d740ac6c],
h4[data-v-d740ac6c],
h5[data-v-d740ac6c],
h6[data-v-d740ac6c],
button[data-v-d740ac6c],
input[data-v-d740ac6c],
textarea[data-v-d740ac6c],
select[data-v-d740ac6c] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-d740ac6c] {
  color: #d9d9d9;
}
a[data-v-d740ac6c] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-d740ac6c] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-d740ac6c]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-d740ac6c]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-d740ac6c]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-d740ac6c] {
  touch-action: pan-y;
}
.ticket[data-v-d740ac6c] {
  padding: 10px;
  display: grid;
  grid-auto-rows: 88px auto;
  border-radius: 5px;
  background-color: #1F2124;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.ticket .ticket-info[data-v-d740ac6c] {
  width: 100%;
  font-weight: bold;
  overflow: hidden;
  display: grid;
  grid-gap: 10px;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.ticket .ticket-info .branch[data-v-d740ac6c] {
  margin-top: 8px;
  margin-bottom: 0;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  display: inline-block;
  font-weight: bold;
  background-color: #595A5A;
  color: #fafafa;
}
.ticket .ticket-info .ticket-info-section[data-v-d740ac6c] {
  margin-top: 15px;
}
.ticket .ticket-info .ticket-info-section p[data-v-d740ac6c] {
  max-height: 100%;
}
.ticket .ticket-info .ticket-info-section p[data-v-d740ac6c]:first-child {
  margin-right: 85px;
  word-wrap: break-word;
}
.ticket .ticket-info .ticket-info-section input[data-v-d740ac6c],
.ticket .ticket-info .ticket-info-section textarea[data-v-d740ac6c],
.ticket .ticket-info .ticket-info-section .priority-selector-input[data-v-d740ac6c],
.ticket .ticket-info .ticket-info-section select[data-v-d740ac6c] {
  width: 100%;
  display: block;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  padding: 5px;
  background: #313438;
}
.ticket .ticket-info .ticket-info-section textarea[data-v-d740ac6c] {
  height: 57px;
  resize: none;
}
.ticket .ticket-info .ticket-info-section input[data-v-d740ac6c]:focus,
.ticket .ticket-info .ticket-info-section textarea[data-v-d740ac6c]:focus,
.ticket .ticket-info .ticket-info-section select[data-v-d740ac6c]:focus {
  outline: none;
}
.ticket .ticket-info .ticket-info-section input[data-v-d740ac6c],
.ticket .ticket-info .ticket-info-section select[data-v-d740ac6c] {
  height: 32px;
}
.ticket .ticket-info .ticket-info-section .new-ticket-select[data-v-d740ac6c] {
  width: 100%;
}
.ticket .ticket-info .ticket-info-section p[data-v-d740ac6c]:first-child {
  margin-top: 0;
}
.ticket .ticket-info .ticket-info-section p[data-v-d740ac6c] {
  margin-bottom: 0;
}
.ticket .ticket-info .ticket-info-section .attached-images[data-v-d740ac6c] {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.ticket .ticket-info .ticket-info-section[data-v-d740ac6c]:first-child {
  margin-top: 0;
}
.ticket .submit-container[data-v-d740ac6c] {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.ticket .submit-container .action-buttons[data-v-d740ac6c] {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}
.ticket .submit-container .action-buttons .attach-input-button[data-v-d740ac6c] {
  font-size: 25px;
  padding: 10px 18px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.ticket .submit-container .action-buttons .attach-input-button input[data-v-d740ac6c] {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.ticket .submit-container .action-buttons .submit[data-v-d740ac6c] {
  height: 32px;
  padding: 5px;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  background: #56b450;
  transition: 0.25s;
}
.ticket .status[data-v-d740ac6c] {
  margin-top: 15px;
  display: grid;
  grid-gap: 5px;
  grid-template-rows: 8px 50px;
}
.ticket .status .line[data-v-d740ac6c] {
  width: 100%;
  border-radius: 5px;
  background-color: #1F2124;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  position: relative;
}
.ticket .status .line .progress[data-v-d740ac6c] {
  height: 100%;
  border-radius: 5px;
  background-color: #7DC579;
}
.ticket .status .line .step-markers[data-v-d740ac6c] {
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  position: absolute;
}
.ticket .status .line .step-markers .marker[data-v-d740ac6c] {
  top: -4px;
  width: 16px;
  height: 16px;
  justify-self: center;
  border-radius: 20%;
  position: relative;
  background-color: #595A5A;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ticket .status .line .step-markers .marker.active[data-v-d740ac6c] {
  box-shadow: none;
  background-color: #7DC579;
}
.ticket .status .steps[data-v-d740ac6c] {
  margin-top: 5px;
  display: grid;
}
.ticket .status .steps span[data-v-d740ac6c] {
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
}
.ticket .status .steps .dummy[data-v-d740ac6c] {
  color: #75797D;
}
.ticket .status-controlls[data-v-d740ac6c] {
  max-width: 100%;
  overflow: hidden;
  margin-top: 15px;
}
.ticket .status-controlls .attached-images-container[data-v-d740ac6c] {
  margin-bottom: 10px;
}
.ticket .status-controlls .attached-images-container span[data-v-d740ac6c] {
  margin: 0;
  padding: 0;
}
.ticket .status-controlls .attached-images-container .attached-images[data-v-d740ac6c] {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.ticket .status-controlls .attached-images[data-v-d740ac6c] {
  margin-top: 10px;
}
.ticket .status-controlls .attached-images .attached-images-container[data-v-d740ac6c] {
  display: flex;
  flex-wrap: wrap;
}
.ticket .status-controlls .add-step[data-v-d740ac6c] {
  margin-bottom: 10px;
  grid-gap: 10px;
}
.ticket .status-controlls .add-step .history-header[data-v-d740ac6c] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticket .status-controlls .add-step .history-header .title[data-v-d740ac6c] {
  max-width: 100px;
  margin: 0;
  padding: 0;
}
.ticket .status-controlls .add-step .history-header .add-step-button[data-v-d740ac6c] {
  padding: 5px 8px;
  border-radius: 5px;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
  display: inline-flex;
  align-items: center;
  transition: 0.25s;
  background-color: #1890ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.ticket .status-controlls .add-step .history-header .add-step-button i[data-v-d740ac6c] {
  transition: 0.25s;
}
.ticket .status-controlls .add-step .history-header .add-step-button .active[data-v-d740ac6c] {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ticket .status-controlls .add-step .history-header .add-step-button span[data-v-d740ac6c] {
  margin-left: 5px;
  font-weight: bold;
}
.ticket .status-controlls .add-step .history-header .add-step-button[data-v-d740ac6c]:hover {
  background-color: #40a9ff;
}
.ticket .status-controlls .add-step .add-step-form[data-v-d740ac6c] {
  margin-top: 10px;
  margin-left: 25px;
  background-color: #313438;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.ticket .status-controlls .add-step .add-step-form .description .label[data-v-d740ac6c],
.ticket .status-controlls .add-step .add-step-form .title .label[data-v-d740ac6c] {
  font-weight: bold;
}
.ticket .status-controlls .add-step .add-step-form .title[data-v-d740ac6c] {
  width: 100%;
}
.ticket .status-controlls .add-step .add-step-form .title input[data-v-d740ac6c] {
  border-radius: 5px;
  width: 100%;
  padding: 8px 10px;
  background: none;
  border: none;
  background-color: #45474A;
  outline: none;
}
.ticket .status-controlls .add-step .add-step-form .description[data-v-d740ac6c] {
  width: 100%;
  margin-top: 10px;
}
.ticket .status-controlls .add-step .add-step-form .description textarea[data-v-d740ac6c] {
  border-radius: 5px;
  width: 100%;
  padding: 8px 10px;
  background: none;
  border: none;
  resize: none;
  outline: none;
  background-color: #45474A;
}
.ticket .status-controlls .add-step .add-step-form .step-pluse-icon-container[data-v-d740ac6c] {
  left: -22px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
}
.ticket .status-controlls .add-step .add-step-form .step-pluse-icon-container .pluse-icon-wrapper[data-v-d740ac6c] {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  background-color: #40a9ff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ticket .status-controlls .add-step .add-step-form .step-pluse-icon-container .pluse-icon-wrapper i[data-v-d740ac6c] {
  font-size: 14px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  color: #ffffff;
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container[data-v-d740ac6c] {
  width: 100%;
  margin-top: 10px;
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  grid-gap: 10px;
  flex-direction: row-reverse;
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .save-button[data-v-d740ac6c],
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .accept-button[data-v-d740ac6c],
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .done-button[data-v-d740ac6c] {
  padding: 8px 10px;
  border-radius: 5px;
  outline-offset: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #56b450;
  transition: 0.25s;
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .save-button span[data-v-d740ac6c],
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .accept-button span[data-v-d740ac6c],
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .done-button span[data-v-d740ac6c] {
  font-weight: bold;
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .accept-button[data-v-d740ac6c] {
  outline: 2px solid #E6BD45;
  background-color: rgba(191, 151, 48, 0.4);
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .accept-button[data-v-d740ac6c]:hover {
  background-color: rgba(191, 151, 48, 0.6);
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .done-button[data-v-d740ac6c] {
  outline: 2px solid #484AD9;
  background-color: rgba(50, 56, 179, 0.4);
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .done-button[data-v-d740ac6c]:hover {
  background-color: rgba(50, 56, 179, 0.8);
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .save-button.inActive[data-v-d740ac6c] {
  display: none;
}
.ticket .status-controlls .add-step .add-step-form .save-buttons-container .save-button[data-v-d740ac6c]:hover {
  background-color: #7DC579;
}
.ticket .status-controlls .ticket-history[data-v-d740ac6c] {
  display: flex;
  position: relative;
}
.ticket .status-controlls .ticket-history .dummy[data-v-d740ac6c] {
  font-weight: bold;
  color: #75797D;
}
.ticket .status-controlls .ticket-history .line[data-v-d740ac6c] {
  width: 8px;
  height: 100%;
  margin-left: 7px;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 5px;
  position: absolute;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: #1F2124;
}
.ticket .status-controlls .ticket-history .line .step-markers[data-v-d740ac6c] {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}
.ticket .status-controlls .ticket-history .line .progress[data-v-d740ac6c] {
  width: 100%;
  border-radius: 5px;
  background-color: #7DC579;
}
.ticket .status-controlls .ticket-history .steps-container[data-v-d740ac6c] {
  width: 100%;
  padding-left: 25px;
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  flex-direction: column-reverse;
}
.ticket .status-controlls .ticket-history .steps-container .step[data-v-d740ac6c] {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  background-color: #313438;
  position: relative;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.ticket .status-controlls .ticket-history .steps-container .step .step-creator-name[data-v-d740ac6c] {
  margin-top: 10px;
  margin-bottom: 0;
  font-style: italic;
}
.ticket .status-controlls .ticket-history .steps-container .step .images-and-done[data-v-d740ac6c] {
  display: flex;
  justify-content: flex-end;
}
.ticket .status-controlls .ticket-history .steps-container .step .images-and-done .done-button[data-v-d740ac6c] {
  padding: 5px 8px;
  display: none;
  -webkit-user-select: none;
          user-select: none;
  align-self: end;
  border-radius: 5px;
  transition: 0.25s;
  background-color: #56b450;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.ticket .status-controlls .ticket-history .steps-container .step .images-and-done .done-button span[data-v-d740ac6c] {
  font-weight: bold;
}
.ticket .status-controlls .ticket-history .steps-container .step .images-and-done .done-button[data-v-d740ac6c]:hover {
  background-color: #7DC579;
}
.ticket .status-controlls .ticket-history .steps-container .step .images-and-done .done-button.active[data-v-d740ac6c] {
  display: block;
}
.ticket .status-controlls .ticket-history .steps-container .step .title-and-date[data-v-d740ac6c] {
  display: grid;
  grid-template-columns: 1fr auto;
}
.ticket .status-controlls .ticket-history .steps-container .step .title-and-date .step-name[data-v-d740ac6c] {
  margin: 0;
}
.ticket .status-controlls .ticket-history .steps-container .step .title-and-date .step-date[data-v-d740ac6c] {
  font-style: italic;
}
.ticket .status-controlls .ticket-history .steps-container .step .step-description[data-v-d740ac6c] {
  max-width: 100%;
  margin: 0;
  word-wrap: break-word;
  margin-top: 10px;
}
.ticket .status-controlls .ticket-history .steps-container .step .step-marker-container[data-v-d740ac6c] {
  top: 0;
  left: -22px;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  position: absolute;
}
.ticket .status-controlls .ticket-history .steps-container .step .step-marker-container .marker[data-v-d740ac6c] {
  width: 16px;
  height: 16px;
  justify-self: center;
  border-radius: 20%;
  background-color: #595A5A;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ticket .status-controlls .ticket-history .steps-container .step .step-marker-container .marker.active[data-v-d740ac6c] {
  box-shadow: none;
  background-color: #7DC579;
}
.ticket .id-and-date[data-v-d740ac6c] {
  top: 0;
  right: 0;
  width: 80px;
  border-radius: 0 0 0 5px;
  display: grid;
  grid-template-rows: 30px auto;
  background-color: #45474A;
  overflow: hidden;
  position: absolute;
}
.ticket .id-and-date .id span[data-v-d740ac6c],
.ticket .id-and-date .date span[data-v-d740ac6c] {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #ffffff;
  font-size: 14px;
}
.ticket .id-and-date .date[data-v-d740ac6c] {
  height: 22px;
  background-color: #313438;
}
.ticket .id-and-date .date span[data-v-d740ac6c] {
  font-size: 12px;
  font-weight: normal;
}
.ticket.controls[data-v-d740ac6c] {
  grid-template-rows: auto auto;
}
.priority-selector-input .ant-select-selector[data-v-d740ac6c] {
  padding: 0;
}
.priority-selector-input .ant-select[data-v-d740ac6c] {
  background: #434343;
}
.ant-select-item[data-v-d740ac6c] {
  padding: 10px;
}
.ant-select[data-v-d740ac6c] {
  border-radius: 5px;
  overflow: hidden;
}
