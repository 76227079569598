@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-ab9c21bc] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-ab9c21bc] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-ab9c21bc] {
  width: 100vw;
  height: 100vh;
}
div[data-v-ab9c21bc] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-ab9c21bc],
p[data-v-ab9c21bc],
span[data-v-ab9c21bc],
h1[data-v-ab9c21bc],
h2[data-v-ab9c21bc],
h3[data-v-ab9c21bc],
h4[data-v-ab9c21bc],
h5[data-v-ab9c21bc],
h6[data-v-ab9c21bc],
button[data-v-ab9c21bc],
input[data-v-ab9c21bc],
textarea[data-v-ab9c21bc],
select[data-v-ab9c21bc] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-ab9c21bc] {
  color: #d9d9d9;
}
a[data-v-ab9c21bc] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-ab9c21bc] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-ab9c21bc]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-ab9c21bc]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-ab9c21bc]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-ab9c21bc] {
  touch-action: pan-y;
}
.taskCard[data-v-ab9c21bc] {
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
  background: #1F2124;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.taskCard .edit-button[data-v-ab9c21bc] {
  top: 15px;
  right: 15px;
  font-size: 25px;
  transition: 0.25s;
  position: absolute;
  color: #d9d9d9;
}
.taskCard .edit-button[data-v-ab9c21bc]:hover {
  color: #fff;
}
.taskCard p[data-v-ab9c21bc] {
  margin: 0;
  margin-bottom: 10px;
}
.taskCard .title[data-v-ab9c21bc] {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
}
.taskCard .description[data-v-ab9c21bc] {
  font-size: 13px;
}
.taskCard .statusWrapper[data-v-ab9c21bc] {
  width: 100%;
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.taskCard .statusWrapper .statusBlock[data-v-ab9c21bc] {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'icon labels' 'timer timer';
}
.taskCard .statusWrapper .statusBlock .iconContainer[data-v-ab9c21bc] {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #313438;
  grid-area: 'icon';
  background-repeat: no-repeat;
}
.taskCard .statusWrapper .statusBlock .awardICON[data-v-ab9c21bc] {
  background-position: center;
  background-image: url('../../assets/svg/icons/award.svg');
}
.taskCard .statusWrapper .statusBlock .clockICON[data-v-ab9c21bc] {
  background-position: center;
  background-image: url('../../assets/svg/icons/clock.svg');
}
.taskCard .statusWrapper .statusBlock .labels[data-v-ab9c21bc] {
  height: 40px;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: 'label';
  overflow: hidden;
}
.taskCard .statusWrapper .statusBlock .labels span[data-v-ab9c21bc]:first-child {
  font-size: 11px;
}
.taskCard .statusWrapper .statusBlock .labels span[data-v-ab9c21bc]:last-child {
  font-size: 14px;
}
.taskCard .statusWrapper .statusBlock .timer[data-v-ab9c21bc] {
  width: 100%;
  height: 8px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #1F2124;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  grid-area: timer;
  overflow: hidden;
}
.taskCard .statusWrapper .statusBlock .timer .progress[data-v-ab9c21bc] {
  width: 0;
  height: 100%;
  border-radius: 0 8px 8px 0;
  background: #7DC579;
  transition: 0.25s;
}
.taskCard.isIncorrect[data-v-ab9c21bc] {
  padding-left: 7px;
  border-left: 8px solid #F2D66F;
}
.taskCard.selected[data-v-ab9c21bc] {
  padding-left: 7px;
  border-left: 8px solid #40a9ff;
}
