@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-8f1c924d] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-8f1c924d] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-8f1c924d] {
  width: 100vw;
  height: 100vh;
}
div[data-v-8f1c924d] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-8f1c924d],
p[data-v-8f1c924d],
span[data-v-8f1c924d],
h1[data-v-8f1c924d],
h2[data-v-8f1c924d],
h3[data-v-8f1c924d],
h4[data-v-8f1c924d],
h5[data-v-8f1c924d],
h6[data-v-8f1c924d],
button[data-v-8f1c924d],
input[data-v-8f1c924d],
textarea[data-v-8f1c924d],
select[data-v-8f1c924d] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-8f1c924d] {
  color: #d9d9d9;
}
a[data-v-8f1c924d] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-8f1c924d] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-8f1c924d]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-8f1c924d]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-8f1c924d]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-8f1c924d] {
  touch-action: pan-y;
  margin: 0 auto;
  padding: 15px 15px 15px 15px;
  height: calc(100vh - 45px);
  overflow-y: auto;
}
.scene .grid[data-v-8f1c924d] {
  margin: 0 auto;
}
.scene.mx-lim[data-v-8f1c924d] {
  max-width: 1000px;
}
.branch[data-v-8f1c924d] {
  max-width: 100%;
  padding-bottom: 0;
  box-sizing: border-box;
}
.branch .recharts-tooltip-cursor[data-v-8f1c924d] {
  background: red;
  fill: red;
}
.branch .recharts-tooltip-cursor .customTooltip[data-v-8f1c924d] {
  background: blue;
}
.branch .recharts-tooltip-cursor .customTooltip p[data-v-8f1c924d] {
  margin: 0;
}
.branch .ant-picker-panel-container[data-v-8f1c924d],
.branch .ant-picker-panels[data-v-8f1c924d] {
  max-width: 100px;
  overflow: hidden;
  flex-wrap: wrap;
}
.branch .ant-picker-dropdown .ant-picker-dropdown-range .ant-picker-dropdown-placement-bottomLeft[data-v-8f1c924d] {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.branch .grid[data-v-8f1c924d] {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  box-sizing: border-box;
}
.branch .grid .selectors[data-v-8f1c924d] {
  -webkit-user-select: none;
          user-select: none;
}
.branch .grid .selectors .circlesContainer[data-v-8f1c924d] {
  overflow-y: auto;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
}
.branch .grid .selectors .circlesContainer .circle[data-v-8f1c924d] {
  height: 100px;
  overflow: hidden;
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  background-color: rgba(31, 33, 36, 0.8);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.branch .grid .selectors .circlesContainer .circle .progress[data-v-8f1c924d] {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.branch .grid .selectors .circlesContainer .circle .progress svg.ant-progress-circle[data-v-8f1c924d] {
  fill: green;
}
.branch .grid .selectors .circlesContainer .circle .progress .progressFormat[data-v-8f1c924d] {
  display: flex;
  flex-direction: column;
}
.branch .grid .selectors .circlesContainer .circle .progress .progressFormat span[data-v-8f1c924d]:first-child {
  font-size: 18px;
  font-weight: bold;
}
.branch .grid .selectors .circlesContainer .circle .progress .progressFormat span[data-v-8f1c924d]:last-child {
  font-size: 12px;
  color: #595959;
}
.branch .grid .selectors .circlesContainer .circle .description[data-v-8f1c924d] {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.branch .grid .selectors .circlesContainer .circle .description span[data-v-8f1c924d] {
  font-size: 16px;
  font-weight: bold;
}
.branch .grid .selectors .circlesContainer .active[data-v-8f1c924d]::after {
  width: 35px;
  height: 35px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: -18px;
  bottom: -18px;
  background: #7DC579;
  position: absolute;
  content: " ";
  transition: 0.25s;
}
.branch .grid .data .chart[data-v-8f1c924d] {
  width: 100%;
  max-width: 100%;
  height: 250px;
  margin-bottom: 10px;
  padding: 10px 0 10px 0;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;
  background-color: rgba(31, 33, 36, 0.8);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.branch .grid .data .chart .custom-tooltip[data-v-8f1c924d] {
  padding: 10px;
  background: #45474A;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.branch .grid .data .chart .custom-tooltip .label[data-v-8f1c924d] {
  margin: 0;
  padding: 2px;
  font-weight: bold;
  font-size: 14px;
}
.branch .grid .data .glass[data-v-8f1c924d] {
  overflow-y: auto;
  margin-bottom: 20px;
}
.branch .grid .data .glass .report[data-v-8f1c924d] {
  margin-bottom: 5px;
  border-radius: 5px;
  overflow: hidden;
  background-color: rgba(31, 33, 36, 0.8);
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.branch .grid .data .glass .report .wrapper[data-v-8f1c924d] {
  padding: 10px;
  display: grid;
  grid-template-areas: "title rating" "description rating";
  grid-template-columns: 1fr 45px;
  grid-gap: 5px;
}
.branch .grid .data .glass .report .wrapper .title[data-v-8f1c924d] {
  grid-area: title;
}
.branch .grid .data .glass .report .wrapper .title span[data-v-8f1c924d] {
  font-size: 18px;
}
.branch .grid .data .glass .report .wrapper .description[data-v-8f1c924d] {
  grid-area: description;
}
.branch .grid .data .glass .report .wrapper .description span[data-v-8f1c924d] {
  font-size: 14px;
}
.branch .grid .data .glass .report .wrapper .rating[data-v-8f1c924d] {
  width: 45px;
  height: 45px;
  background-color: #595959;
  grid-area: rating;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.branch .grid .data .glass .report .wrapper .rating span[data-v-8f1c924d] {
  font-size: 22px;
  font-weight: bold;
}
.branch .grid .data .glass .report .wrapper .rating-0[data-v-8f1c924d] {
  background-color: #EB5757;
}
.branch .grid .data .glass .report .wrapper .rating-1[data-v-8f1c924d] {
  background-color: #EB5757;
}
.branch .grid .data .glass .report .wrapper .rating-2[data-v-8f1c924d] {
  background-color: #EB5757;
}
.branch .grid .data .glass .report .wrapper .rating-3[data-v-8f1c924d] {
  background-color: #F2994A;
}
.branch .grid .data .glass .report .wrapper .rating-4[data-v-8f1c924d] {
  background-color: #E6BD45;
}
.branch .grid .data .glass .report .wrapper .rating-5[data-v-8f1c924d] {
  background-color: #7DC579;
}
.branch .grid .data .glass .report .wrapper .rating-true[data-v-8f1c924d] {
  background-color: #7DC579;
}
.branch .grid .data .glass .report .wrapper .rating-false[data-v-8f1c924d] {
  background-color: #EB5757;
}
.branch .grid .data .glass .report .feedBack[data-v-8f1c924d] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  grid-gap: 5px;
  padding: 10px;
  width: 100%;
  grid-template-areas: "message ticketIDContainer" "photos photos";
  background: #313438;
}
.branch .grid .data .glass .report .feedBack .message[data-v-8f1c924d] {
  grid-area: message;
}
.branch .grid .data .glass .report .feedBack .ticketIDContainer[data-v-8f1c924d] {
  height: 34px;
  padding: 6px;
  border-radius: 8px;
  background: #EB5757;
  grid-area: ticketIDContainer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.branch .grid .data .glass .report .feedBack .ticketIDContainer .ticketID[data-v-8f1c924d] {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.branch .grid .data .glass .report .feedBack .photos[data-v-8f1c924d] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-area: photos;
}
.branch .datePickerContainer[data-v-8f1c924d] {
  width: 100%;
  margin-bottom: 10px;
}
.branch .datePickerContainer .ant-picker.ant-picker-range.datePicker[data-v-8f1c924d] {
  width: 100%;
}
.branch .datePickerContainer .ant-picker-dropdown[data-v-8f1c924d] {
  max-width: 100vw;
}
.branch .datePickerContainer .datePicker[data-v-8f1c924d] {
  max-width: 100%;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  padding: 12px;
  box-sizing: border-box;
  background-color: rgba(31, 33, 36, 0.8);
  border: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.branch .datePickerContainer .datePicker .anticon-swap-right svg[data-v-8f1c924d] {
  fill: red;
}
/* Landscape phones and smaller */
@media (max-width: 480px) {
.hideOnSmallScreens[data-v-8f1c924d] {
    display: none;
}
.branch .grid .selectors .circlesContainer[data-v-8f1c924d],
  .pos-statistics .grid .selectors .circlesContainer[data-v-8f1c924d] {
    grid-template-columns: 1fr;
}
.tickets .selectors[data-v-8f1c924d] {
    grid-template-columns: 1fr;
}
.tickets .selectors .complited-selector[data-v-8f1c924d] {
    margin-left: 15px;
    margin-right: 15px;
}
.tickets .selectors .branches-selector[data-v-8f1c924d] {
    padding-left: 15px;
}
}
/* Portrait tablets and small desktops */
@media (max-width: 768px) {
.logIn.scene .login-form[data-v-8f1c924d] {
    padding-top: 50px;
    bottom: 0;
    width: 100vw;
    height: 400px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: absolute;
    background-color: #101012;
}
.logIn.scene .logo[data-v-8f1c924d] {
    left: 0;
    width: 100vw;
    padding-left: 0;
    position: absolute;
}
.logIn.scene .circles[data-v-8f1c924d] {
    display: block;
}
.logIn.scene .tree[data-v-8f1c924d] {
    display: none;
}
.logIn.scene .build[data-v-8f1c924d] {
    left: 0;
    width: 100vw;
    text-align: center;
    z-index: 3;
}
}
@media (min-width: 768px) {
.stage > .stage-content > .title-bar > .actions .search[data-v-8f1c924d] {
    display: grid;
}
.stage > .stage-content > .title-bar > .actions .top-nav-bar-search-action-button[data-v-8f1c924d] {
    display: none;
}
.workers.scene .sceneTitle[data-v-8f1c924d] {
    grid-template-columns: 1fr auto;
}
.scene[data-v-8f1c924d] {
    grid-area: stage;
}
.CCTV .grid[data-v-8f1c924d],
  .CCTVView .grid[data-v-8f1c924d],
  .branchs .grid[data-v-8f1c924d],
  .home .grid[data-v-8f1c924d],
  .lern .grid[data-v-8f1c924d],
  .network .grid[data-v-8f1c924d],
  .tickets .grid[data-v-8f1c924d] {
    grid-template-columns: repeat(2, 1fr);
}
.branch[data-v-8f1c924d],
  .pos-statistics[data-v-8f1c924d] {
    display: flex;
}
.branch .grid[data-v-8f1c924d],
  .pos-statistics .grid[data-v-8f1c924d] {
    overflow-y: auto;
    grid-template-columns: auto 1fr;
}
.branch .grid .selectors[data-v-8f1c924d],
  .pos-statistics .grid .selectors[data-v-8f1c924d] {
    padding-top: 0;
    overflow-y: auto;
}
.branch .grid .data[data-v-8f1c924d],
  .pos-statistics .grid .data[data-v-8f1c924d] {
    overflow-y: auto;
}
.workers.scene .profiles[data-v-8f1c924d] {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
}
@media (max-width: 991px) {
.navBar[data-v-8f1c924d] {
    width: 200px;
}
.navBar .back-button[data-v-8f1c924d] {
    width: 150px;
}
.navBar .back-button span[data-v-8f1c924d] {
    display: block;
}
.navBar .buttonsContainer[data-v-8f1c924d] {
    width: 100%;
}
.navBar .buttonsContainer .navLinkButton[data-v-8f1c924d] {
    width: 150px;
    justify-content: flex-start;
}
.navBar .buttonsContainer .navLinkButton span[data-v-8f1c924d] {
    display: block;
}
.navBar .account-wrapper[data-v-8f1c924d] {
    width: 100%;
}
.navBar .account-wrapper .account[data-v-8f1c924d] {
    width: 150px;
    grid-template-columns: 50px auto;
}
.navBar .account-wrapper .account span[data-v-8f1c924d] {
    display: flex;
}
}
/* Portrait tablets and medium desktops */
@media (min-width: 992px) {
.navBar .buttonsContainer .navLinkButton.selected .marker[data-v-8f1c924d] {
    display: none;
}
.account.scene > .profile > .wrapper .burger[data-v-8f1c924d] {
    display: none;
}
.stage .stage-content[data-v-8f1c924d] {
    width: calc(100vw - 80px);
    box-shadow: none;
    left: 80px;
}
.stage .stage-content .title-bar[data-v-8f1c924d] {
    padding-left: 15px;
    grid-template-columns: 1fr auto;
}
.stage .stage-content .title-bar .burger[data-v-8f1c924d] {
    display: none;
}
.check-editor.scene[data-v-8f1c924d] {
    overflow: hidden;
}
.check-editor.scene .container[data-v-8f1c924d] {
    grid-template-columns: auto 1fr;
}
.check-editor.scene .container .editor[data-v-8f1c924d] {
    width: 400px;
}
.logIn.scene .tree[data-v-8f1c924d] {
    width: 850px;
    right: 25px;
}
.workers.scene .profiles[data-v-8f1c924d] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
}
/* Large desktops and laptops */
@media (min-width: 1200px) {
.navBar .buttonsContainer .navLinkButton.selected .marker[data-v-8f1c924d] {
    display: block;
}
.stage .stage-content[data-v-8f1c924d] {
    width: calc(100vw - 200px);
    box-shadow: none;
    left: 200px;
}
.stage .back-button[data-v-8f1c924d] {
    width: 150px;
}
.stage .back-button span[data-v-8f1c924d] {
    display: block;
}
.navBar[data-v-8f1c924d] {
    width: 200px;
}
.navBar .buttonsContainer[data-v-8f1c924d] {
    width: 100%;
}
.navBar .buttonsContainer .navLinkButton[data-v-8f1c924d] {
    width: 150px;
    justify-content: flex-start;
}
.navBar .buttonsContainer .navLinkButton span[data-v-8f1c924d] {
    display: block;
}
.navBar .account-wrapper[data-v-8f1c924d] {
    width: 100%;
}
.navBar .account-wrapper .account[data-v-8f1c924d] {
    width: 150px;
    grid-template-columns: 50px auto;
}
.navBar .account-wrapper .account span[data-v-8f1c924d] {
    display: flex;
}
.profile .baseInfoWrapper[data-v-8f1c924d] {
    grid-template-columns: auto 1fr;
}
.profile .baseInfoWrapper .name[data-v-8f1c924d] {
    font-size: 30px;
}
.CCTV .grid[data-v-8f1c924d],
  .CCTVView .grid[data-v-8f1c924d],
  .branchs .grid[data-v-8f1c924d],
  .home .grid[data-v-8f1c924d],
  .lern .grid[data-v-8f1c924d],
  .network .grid[data-v-8f1c924d],
  .tickets .grid[data-v-8f1c924d] {
    max-width: 1250px;
    grid-template-columns: repeat(3, 1fr);
}
.workers.scene .profiles[data-v-8f1c924d] {
    display: flex;
    flex-wrap: wrap;
}
}
@media (min-width: 1600px) {
.CCTV .grid[data-v-8f1c924d],
  .CCTVView .grid[data-v-8f1c924d],
  .branchs .grid[data-v-8f1c924d],
  .home .grid[data-v-8f1c924d],
  .lern .grid[data-v-8f1c924d],
  .network .grid[data-v-8f1c924d],
  .tickets .grid[data-v-8f1c924d],
  .workers .grid[data-v-8f1c924d] {
    max-width: 1800px;
    grid-template-columns: repeat(4, 1fr);
}
}
@media (min-width: 1700px) {
.network .grid[data-v-8f1c924d] {
    max-width: 1800px;
    grid-template-columns: repeat(5, 1fr);
}
}
