@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-3a3b1c98] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-3a3b1c98] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-3a3b1c98] {
  width: 100vw;
  height: 100vh;
}
div[data-v-3a3b1c98] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-3a3b1c98],
p[data-v-3a3b1c98],
span[data-v-3a3b1c98],
h1[data-v-3a3b1c98],
h2[data-v-3a3b1c98],
h3[data-v-3a3b1c98],
h4[data-v-3a3b1c98],
h5[data-v-3a3b1c98],
h6[data-v-3a3b1c98],
button[data-v-3a3b1c98],
input[data-v-3a3b1c98],
textarea[data-v-3a3b1c98],
select[data-v-3a3b1c98] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-3a3b1c98] {
  color: #d9d9d9;
}
a[data-v-3a3b1c98] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-3a3b1c98] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-3a3b1c98]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-3a3b1c98]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-3a3b1c98]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-3a3b1c98] {
  touch-action: pan-y;
}
.lds-facebook[data-v-3a3b1c98] {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div[data-v-3a3b1c98] {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #A5ACB0;
  -webkit-animation: lds-facebook-data-v-3a3b1c98 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          animation: lds-facebook-data-v-3a3b1c98 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div[data-v-3a3b1c98]:nth-child(1) {
  left: 8px;
  -webkit-animation-delay: -0.24s;
          animation-delay: -0.24s;
}
.lds-facebook div[data-v-3a3b1c98]:nth-child(2) {
  left: 32px;
  -webkit-animation-delay: -0.12s;
          animation-delay: -0.12s;
}
.lds-facebook div[data-v-3a3b1c98]:nth-child(3) {
  left: 56px;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
@-webkit-keyframes lds-facebook-data-v-3a3b1c98 {
0% {
    top: 8px;
    height: 64px;
}
50%,
  100% {
    top: 24px;
    height: 32px;
}
}
@keyframes lds-facebook-data-v-3a3b1c98 {
0% {
    top: 8px;
    height: 64px;
}
50%,
  100% {
    top: 24px;
    height: 32px;
}
}
