@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-de116371] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-de116371] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-de116371] {
  width: 100vw;
  height: 100vh;
}
div[data-v-de116371] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-de116371],
p[data-v-de116371],
span[data-v-de116371],
h1[data-v-de116371],
h2[data-v-de116371],
h3[data-v-de116371],
h4[data-v-de116371],
h5[data-v-de116371],
h6[data-v-de116371],
button[data-v-de116371],
input[data-v-de116371],
textarea[data-v-de116371],
select[data-v-de116371] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-de116371] {
  color: #d9d9d9;
}
a[data-v-de116371] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-de116371] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-de116371]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-de116371]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-de116371]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-de116371] {
  touch-action: pan-y;
}
.logIn.scene[data-v-de116371] {
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.logIn.scene .logo[data-v-de116371] {
  top: 25px;
  left: 32.5px;
  width: auto;
  height: 60px;
  position: absolute;
}
.logIn.scene .circles[data-v-de116371] {
  top: 0;
  left: 0;
  display: none;
  position: absolute;
}
.logIn.scene .circles path[data-v-de116371] {
  opacity: 0.5;
}
.logIn.scene .login-form[data-v-de116371] {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 220px;
  z-index: 2;
}
.logIn.scene .login-form .qr-code-wrapper[data-v-de116371] {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  background-color: #fafafa;
}
.logIn.scene .login-form .qr-code-wrapper .qr-code[data-v-de116371] {
  width: 100%;
  height: 100%;
}
.logIn.scene .login-form .login-form-input-wrapper[data-v-de116371] {
  position: relative;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.logIn.scene .login-form .login-form-input-wrapper input[data-v-de116371] {
  width: 220px;
  height: 45px;
  border: none;
  color: #1f1f1f;
  border-radius: 10px;
  text-align: center;
  position: relative;
}
.logIn.scene .login-form .login-form-input-wrapper input[data-v-de116371]::-webkit-input-placeholder {
  color: #434343;
}
.logIn.scene .login-form .login-form-input-wrapper input[data-v-de116371]::placeholder {
  color: #434343;
}
.logIn.scene .login-form .login-form-input-wrapper input[data-v-de116371]:focus {
  outline: none;
}
.logIn.scene .login-form .login-form-input-wrapper input[data-v-de116371]::-webkit-inner-spin-button,
.logIn.scene .login-form .login-form-input-wrapper input[data-v-de116371]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.logIn.scene .login-form .login-form-input-wrapper input[type=number][data-v-de116371] {
  -moz-appearance: textfield;
}
.logIn.scene .login-form .login-form-input-wrapper .fa-exclamation[data-v-de116371] {
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  position: absolute;
  color: #EB5757;
  font-weight: bold;
}
.logIn.scene .login-form .msm-login-btn[data-v-de116371] {
  height: 45px;
  width: 220px;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  font-size: 16px;
  font-weight: bold;
  transition: 0.25s;
  background-color: #7DC579;
}
.logIn.scene .login-form .msm-login-btn[data-v-de116371]:hover {
  background-color: #9ACD96;
}
.logIn.scene .login-form .msm-login-btn.first[data-v-de116371] {
  background-color: #484AD9;
}
.logIn.scene .login-form .msm-login-btn.first[data-v-de116371]:hover {
  background-color: #6461FF;
}
.logIn.scene .login-form .msm-login-btn.back[data-v-de116371] {
  background-color: #EB5757;
}
.logIn.scene .login-form .msm-login-btn.back[data-v-de116371]:hover {
  background-color: #F78783;
}
.logIn.scene .tree[data-v-de116371] {
  bottom: 0;
  width: 700px;
  height: auto;
  transition: 0.25s;
  position: absolute;
  z-index: 0;
}
.logIn.scene .build[data-v-de116371] {
  right: 30px;
  bottom: 30px;
  margin: 0;
  position: absolute;
}
.logIn.scene .build a[data-v-de116371],
.logIn.scene .build span[data-v-de116371] {
  color: #828282;
  margin: 0;
}
.logIn.scene .build a[data-v-de116371] {
  color: #eee;
  text-decoration: underline;
}
.logIn.scene .build span[data-v-de116371] {
  margin-left: 5px;
}
.logIn.scene .info[data-v-de116371] {
  left: 30px;
  bottom: 30px;
  width: 45px;
  height: 45px;
  border: 3px solid #828282;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  display: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #828282;
  position: absolute;
}
/* Landscape phones and smaller */
@media (max-width: 480px) {
.hideOnSmallScreens[data-v-de116371] {
    display: none;
}
.branch .grid .selectors .circlesContainer[data-v-de116371],
  .pos-statistics .grid .selectors .circlesContainer[data-v-de116371] {
    grid-template-columns: 1fr;
}
.tickets .selectors[data-v-de116371] {
    grid-template-columns: 1fr;
}
.tickets .selectors .complited-selector[data-v-de116371] {
    margin-left: 15px;
    margin-right: 15px;
}
.tickets .selectors .branches-selector[data-v-de116371] {
    padding-left: 15px;
}
}
/* Portrait tablets and small desktops */
@media (max-width: 768px) {
.logIn.scene .login-form[data-v-de116371] {
    padding-top: 50px;
    bottom: 0;
    width: 100vw;
    height: 400px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: absolute;
    background-color: #101012;
}
.logIn.scene .logo[data-v-de116371] {
    left: 0;
    width: 100vw;
    padding-left: 0;
    position: absolute;
}
.logIn.scene .circles[data-v-de116371] {
    display: block;
}
.logIn.scene .tree[data-v-de116371] {
    display: none;
}
.logIn.scene .build[data-v-de116371] {
    left: 0;
    width: 100vw;
    text-align: center;
    z-index: 3;
}
}
@media (min-width: 768px) {
.stage > .stage-content > .title-bar > .actions .search[data-v-de116371] {
    display: grid;
}
.stage > .stage-content > .title-bar > .actions .top-nav-bar-search-action-button[data-v-de116371] {
    display: none;
}
.workers.scene .sceneTitle[data-v-de116371] {
    grid-template-columns: 1fr auto;
}
.scene[data-v-de116371] {
    grid-area: stage;
}
.CCTV .grid[data-v-de116371],
  .CCTVView .grid[data-v-de116371],
  .branchs .grid[data-v-de116371],
  .home .grid[data-v-de116371],
  .lern .grid[data-v-de116371],
  .network .grid[data-v-de116371],
  .tickets .grid[data-v-de116371] {
    grid-template-columns: repeat(2, 1fr);
}
.branch[data-v-de116371],
  .pos-statistics[data-v-de116371] {
    display: flex;
}
.branch .grid[data-v-de116371],
  .pos-statistics .grid[data-v-de116371] {
    overflow-y: auto;
    grid-template-columns: auto 1fr;
}
.branch .grid .selectors[data-v-de116371],
  .pos-statistics .grid .selectors[data-v-de116371] {
    padding-top: 0;
    overflow-y: auto;
}
.branch .grid .data[data-v-de116371],
  .pos-statistics .grid .data[data-v-de116371] {
    overflow-y: auto;
}
.workers.scene .profiles[data-v-de116371] {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
}
@media (max-width: 991px) {
.navBar[data-v-de116371] {
    width: 200px;
}
.navBar .back-button[data-v-de116371] {
    width: 150px;
}
.navBar .back-button span[data-v-de116371] {
    display: block;
}
.navBar .buttonsContainer[data-v-de116371] {
    width: 100%;
}
.navBar .buttonsContainer .navLinkButton[data-v-de116371] {
    width: 150px;
    justify-content: flex-start;
}
.navBar .buttonsContainer .navLinkButton span[data-v-de116371] {
    display: block;
}
.navBar .account-wrapper[data-v-de116371] {
    width: 100%;
}
.navBar .account-wrapper .account[data-v-de116371] {
    width: 150px;
    grid-template-columns: 50px auto;
}
.navBar .account-wrapper .account span[data-v-de116371] {
    display: flex;
}
}
/* Portrait tablets and medium desktops */
@media (min-width: 992px) {
.navBar .buttonsContainer .navLinkButton.selected .marker[data-v-de116371] {
    display: none;
}
.account.scene > .profile > .wrapper .burger[data-v-de116371] {
    display: none;
}
.stage .stage-content[data-v-de116371] {
    width: calc(100vw - 80px);
    box-shadow: none;
    left: 80px;
}
.stage .stage-content .title-bar[data-v-de116371] {
    padding-left: 15px;
    grid-template-columns: 1fr auto;
}
.stage .stage-content .title-bar .burger[data-v-de116371] {
    display: none;
}
.check-editor.scene[data-v-de116371] {
    overflow: hidden;
}
.check-editor.scene .container[data-v-de116371] {
    grid-template-columns: auto 1fr;
}
.check-editor.scene .container .editor[data-v-de116371] {
    width: 400px;
}
.logIn.scene .tree[data-v-de116371] {
    width: 850px;
    right: 25px;
}
.workers.scene .profiles[data-v-de116371] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
}
/* Large desktops and laptops */
@media (min-width: 1200px) {
.navBar .buttonsContainer .navLinkButton.selected .marker[data-v-de116371] {
    display: block;
}
.stage .stage-content[data-v-de116371] {
    width: calc(100vw - 200px);
    box-shadow: none;
    left: 200px;
}
.stage .back-button[data-v-de116371] {
    width: 150px;
}
.stage .back-button span[data-v-de116371] {
    display: block;
}
.navBar[data-v-de116371] {
    width: 200px;
}
.navBar .buttonsContainer[data-v-de116371] {
    width: 100%;
}
.navBar .buttonsContainer .navLinkButton[data-v-de116371] {
    width: 150px;
    justify-content: flex-start;
}
.navBar .buttonsContainer .navLinkButton span[data-v-de116371] {
    display: block;
}
.navBar .account-wrapper[data-v-de116371] {
    width: 100%;
}
.navBar .account-wrapper .account[data-v-de116371] {
    width: 150px;
    grid-template-columns: 50px auto;
}
.navBar .account-wrapper .account span[data-v-de116371] {
    display: flex;
}
.profile .baseInfoWrapper[data-v-de116371] {
    grid-template-columns: auto 1fr;
}
.profile .baseInfoWrapper .name[data-v-de116371] {
    font-size: 30px;
}
.CCTV .grid[data-v-de116371],
  .CCTVView .grid[data-v-de116371],
  .branchs .grid[data-v-de116371],
  .home .grid[data-v-de116371],
  .lern .grid[data-v-de116371],
  .network .grid[data-v-de116371],
  .tickets .grid[data-v-de116371] {
    max-width: 1250px;
    grid-template-columns: repeat(3, 1fr);
}
.workers.scene .profiles[data-v-de116371] {
    display: flex;
    flex-wrap: wrap;
}
}
@media (min-width: 1600px) {
.CCTV .grid[data-v-de116371],
  .CCTVView .grid[data-v-de116371],
  .branchs .grid[data-v-de116371],
  .home .grid[data-v-de116371],
  .lern .grid[data-v-de116371],
  .network .grid[data-v-de116371],
  .tickets .grid[data-v-de116371],
  .workers .grid[data-v-de116371] {
    max-width: 1800px;
    grid-template-columns: repeat(4, 1fr);
}
}
@media (min-width: 1700px) {
.network .grid[data-v-de116371] {
    max-width: 1800px;
    grid-template-columns: repeat(5, 1fr);
}
}
