@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-Medium.ttf') format("opentype"), url('../../assets/fonts/DMSans/DMSans-Medium.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Medium.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Medium.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Regular.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Regular.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Regular.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Regular.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-Bold.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Bold.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Bold.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Bold.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: bold;
  src: url('../../assets/fonts/DMSans/DMSans-BoldItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-BoldItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: normal;
  src: url('../../assets/fonts/DMSans/DMSans-MediumItalic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-MediumItalic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: italic;
  font-weight: lighter;
  src: url('../../assets/fonts/DMSans/DMSans-Italic.ttf') format("truetype"), url('../../assets/fonts/DMSans/DMSans-Italic.woff2') format("woff2"), url('../../assets/fonts/DMSans/DMSans-Italic.woff') format("woff"), url('../../assets/fonts/DMSans/DMSans-Italic.eot') format("eot");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/DMSans/DMSans-normal-500-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.theme-bg-thone-0[data-v-220a1182] {
  background-image: linear-gradient(-45deg, #262729, #161719);
}
body[data-v-220a1182] {
  overscroll-behavior-y: contain;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
  background-color: linear-gradient(-45deg, #101112 100%, #0e0e0f 100%);
}
#root[data-v-220a1182] {
  width: 100vw;
  height: 100vh;
}
div[data-v-220a1182] {
  font-family: Roboto, Arial, DMSans;
  box-sizing: border-box;
}
a[data-v-220a1182],
p[data-v-220a1182],
span[data-v-220a1182],
h1[data-v-220a1182],
h2[data-v-220a1182],
h3[data-v-220a1182],
h4[data-v-220a1182],
h5[data-v-220a1182],
h6[data-v-220a1182],
button[data-v-220a1182],
input[data-v-220a1182],
textarea[data-v-220a1182],
select[data-v-220a1182] {
  font-family: Roboto, Arial, DMSans;
  color: #d9d9d9;
}
i[data-v-220a1182] {
  color: #d9d9d9;
}
a[data-v-220a1182] {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
*[data-v-220a1182] {
  scrollbar-color: #262626 rgba(0, 0, 0, 0);
}
*[data-v-220a1182]::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0;
}
*[data-v-220a1182]::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
*[data-v-220a1182]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #262626;
}
.scene[data-v-220a1182] {
  touch-action: pan-y;
}
.ChecklistResult[data-v-220a1182] {
  padding: 10px;
  overflow: auto;
}
.ChecklistResult .rating[data-v-220a1182] {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;
}
.ChecklistResult .title[data-v-220a1182] {
  padding: 10px 0;
  font-size: 20px;
  font-weight: bold;
}
.ChecklistResult .filters .selector[data-v-220a1182] {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 6px;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  border-radius: 5px;
  border: 2px solid #F2C94C;
  background-color: rgba(242, 201, 76, 0.18);
}
.ChecklistResult .filters .selector[data-v-220a1182]:nth-child(2) {
  border: 2px solid #F26A4C;
  background-color: rgba(242, 106, 76, 0.18);
}
.ChecklistResult .filters .selector[data-v-220a1182]:nth-child(3) {
  border: 2px solid #4C84F2;
  background-color: rgba(76, 132, 242, 0.18);
}
.ChecklistResult .checksContainer[data-v-220a1182] {
  margin-top: 10px;
}
.ChecklistResult .ratingWrapper[data-v-220a1182] {
  padding: 15px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 50px 1fr;
  overflow: hidden;
  background-color: #313438;
  position: relative;
  box-shadow: 0 5px 5px -5px #000;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
.ChecklistResult .ratingWrapper .ant-progress-inner[data-v-220a1182] {
  padding: 15px;
}
.ChecklistResult .ratingWrapper .ratingPercentLabelWrapper[data-v-220a1182] {
  width: 60px;
  height: 60px;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
}
.ChecklistResult .ratingWrapper .ratingPercentLabel[data-v-220a1182] {
  font-size: 15px;
}
.ChecklistResult .ratingWrapper .ratingPercentSing[data-v-220a1182] {
  font-size: 12px;
}
.ChecklistResult .ratingWrapper .label[data-v-220a1182] {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bbb;
}
.ChecklistResult .ratingWrapper.active[data-v-220a1182]:after {
  bottom: -70px;
  right: -70px;
  width: 100px;
  height: 100px;
  background-color: #9ACD96;
  position: absolute;
  content: '';
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  box-shadow: 0 10px 4px #9ACD96;
}
